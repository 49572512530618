import Axios from 'axios'
import { ENDPOINTS } from 'config'

export const axios = Axios.create({
  baseURL: ENDPOINTS.BASE_URL
})

axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  async (error) => {
    console.log('***CUSTOM AXIOS API ERROR***', error)

    if (error?.response?.status === 401) {
      console.log('---401 logout---')
    } else if (error?.response?.status === 404) {
      console.log('---404---')
    } else if (error?.response?.status === 500) {
      console.log('---500---')
    }

    return await Promise.reject(error)
  }
)
