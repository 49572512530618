import * as XLSX from 'xlsx'

export const exportExcel = (
  excelData: string[][],
  sheetTitle: string,
  sheetFileName: string
): void => {
  const ws = XLSX.utils.aoa_to_sheet(excelData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetTitle)
  XLSX.writeFile(wb, sheetFileName)
}
