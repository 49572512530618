import { yupResolver } from '@hookform/resolvers/yup'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled
} from '@mui/material'
import { ErrorText, MButton, Text } from 'components'
import { EMAIL, ROUTES_PATH } from 'config'
import { useAuth } from 'context'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import * as yup from 'yup'

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required()
  })
  .required()

export interface IAuthForm {
  email: string
  password: string
}

export const Form: React.FC = () => {
  const navigate = useNavigate()
  const { rememberMe, handleRememberMe, handleLogin, isLoading } = useAuth()
  const [error, setError] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const isMobile = useMobileBreakpoints()
  const [email, setEmail] = useLocalStorage(EMAIL, '')
  const { handleSubmit, control, formState: { errors, isValid } } = useForm<IAuthForm>({
    mode: 'all',
    defaultValues: {
      email: email ?? '',
      password: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data: IAuthForm): Promise<void> => {
    try {
      const result = await handleLogin(data)
      if (result) {
        navigate(ROUTES_PATH.DASHBOARD)
        if (rememberMe) {
          setEmail(data.email)
        } else {
          setEmail('')
        }
      }
    } catch (err: any) {
      setError(err)
    }
  }
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    event.preventDefault()
  }

  const handleRegister = (): void => {
    navigate(ROUTES_PATH.SIGNUP)
  }

  const handleChange = (e: any, onChange: any): void => {
    onChange(e.target.value)
  }

  return (
    <form method="post" onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <FormLabel htmlFor="username">Email address</FormLabel>
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <OutlinedInput
              name={name}
              placeholder="Enter your email address"
              onChange={(e) => {
                handleChange(e, onChange)
              }}
              value={value}
              type="email"
              autoComplete="username"
              fullWidth
            />
          )}
        />
        {(errors.email != null) && (
          <ErrorText>{errors.email.message}</ErrorText>
        )}
      </Box>
      <Box mt={2}>
        <FormLabel htmlFor="password">Password</FormLabel>
        <Controller
          name="password"
          control={control}
          render={({ field: { onChange, value, name } }): JSX.Element => (
            <OutlinedInput
              id="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="current-password"
              placeholder="Enter your password"
              onChange={(e) => {
                handleChange(e, onChange)
              }}
              value={value}
              name={name}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          )}
        />
        {(errors.password != null) && (
          <ErrorText>{errors.password.message}</ErrorText>
        )}
      </Box>

      <FormControlLabel
        control={<Checkbox />}
        label="Remember Me"
        checked={rememberMe}
        onChange={() => { handleRememberMe(!rememberMe) }}
      />


      {(error !== '') && (
        <Text color="#a50b04" fontSize="16px" margin="8px 0">
          {error}
        </Text>
      )}

      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="space-between"
        pt={2}
        gap={2}
      >
        <MButton
          variant="contained"
          type="submit"
          size="large"
          rounded="true"
          loading={isLoading}
          disabled={!isValid}
          fullWidth
        >
          Login
        </MButton>
        <MButton
          variant="outlined"
          type="button"
          size="large"
          rounded="true"
          onClick={handleRegister}
          fullWidth
        >
          Register
        </MButton>
      </Box>
    </form>
  )
}

export const FormLabel = styled(InputLabel)({
  fontSize: '12px',
  lineHeight: '18px',
  color: '#5D6365',
  marginBottom: '4px'
})
