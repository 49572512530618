import { Box, Grid, IconButton, styled } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Text } from 'components'

interface IItem {
  Icon: any
  title: string
  route: string
}

export const Item = ({ title, Icon, route }: IItem): JSX.Element => {
  const navigate = useNavigate()

  const handleItem = (): void => {
    if (title === 'Payment Plan') {
      window.open(route, '_blank')
    } else {
      navigate(route)
    }
  }

  return (
    <Grid item xs={12} sm={12} md={3} xl={3}>
      <Wrapper onClick={handleItem}>
        <IconButton sx={{ background: '#E7EEF2', borderRadius: '2px' }}>
          <Icon sx={{ color: '#0092BD' }} />
        </IconButton>

        <Text color="#010B0E" fontSize="16px" margin="0 0 0 8px">
          {title}
        </Text>
      </Wrapper>
    </Grid>
  )
}
const Wrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  background: '#F7F9FB',
  borderRadius: '4px',
  padding: '12px 8px',
  '&:hover': {
    cursor: 'pointer'
  }
})
