import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Dialog,
  InputLabel,
  Link,
  OutlinedInput,
  Typography,
  styled
} from '@mui/material'
import { Auth } from 'aws-amplify'
import { ErrorText, MButton, SvgImage, Text } from 'components'
import { ALIAS_EXISTS_EXCEPTION, MESSAGES, ROUTES_PATH, SUCCESS, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'
import * as yup from 'yup'

interface IFormData {
  email: string
  emailConfirm: string
  code: string
}

interface ICodeFormData {
  code: string
}

export interface IMailingAddress {
  addressLine1: string
  addressLine2: string
  postal: string
  city: string
  state: string
}

export const EditEmailForm = ({
  setIsEmailEditing,
  handleEditEmail,
  open,
  isEmailUpdateSuccess,
  isLoading,
  setIsEmailUpdateSuccess
}: {
  setIsEmailEditing: any
  handleEditEmail: (
    event: React.ChangeEvent<HTMLInputElement>,
    email: string
  ) => void
  setIsEmailUpdateSuccess: (data: boolean) => void
  open: boolean
  isEmailUpdateSuccess: boolean
  isLoading: boolean
}): JSX.Element => {
  const { account } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [isNext, setIsNext] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>({
    email: '',
    emailConfirm: '',
    code: ''
  })
  const [authToken] = useLocalStorage(USER_TOKEN, '')

  const schema = yup
    .object({
      email: yup.string().email().required('Email is required'),
      emailConfirm: yup
        .string()
        .oneOf([yup.ref('email'), null], 'Email must match')
        .notOneOf(
          [account?.myAccount?.email],
          'New email must be different from the old email'
        )
    })
    .required()

  const codeSchema = yup
    .object({
      code: yup.string().required('Code is required')
    })
    .required()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IFormData>({
    mode: 'all',
    defaultValues: {
      email: '',
      emailConfirm: ''
    },
    resolver: yupResolver(schema)
  })
  const {
    control: codeControl,
    formState: { errors: codeError, isValid: isCodeValid }
  } = useForm<ICodeFormData>({
    mode: 'all',
    defaultValues: {
      code: ''
    },
    resolver: yupResolver(codeSchema)
  })

  const handleVerifyEmail = async (event: any): Promise<void> => {
    if (!(formData.code) || !formData.email) return
    try {
      const user = await Auth.currentAuthenticatedUser()
      const response = await Auth.verifyUserAttributeSubmit(
        user,
        'email',
        formData.code
      )
      if (response === SUCCESS) {
        handleEditEmail(event, formData.email)
      }
    } catch (e: any) {
      const { code } = e
      if (code === ALIAS_EXISTS_EXCEPTION) setIsEmailEditing(false)
      enqueueSnackbar(e?.message || MESSAGES.SOMETHING_WRONG_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  const resendCode = async (): Promise<void> => {
    try {
      const username = account?.myAccount?.email ?? null
      if (username === null) return
      const response = await Auth.resendSignUp(username)
      if (response?.CodeDeliveryDetails) {
        enqueueSnackbar(MESSAGES.VERIFICATION_RESENT, {
          variant: 'success',
          autoHideDuration: 3000
        })
      } else {
        console.error(response)
        enqueueSnackbar(MESSAGES.WRONG_ERROR, {
          variant: 'error',
          autoHideDuration: 3000
        })
      }
    } catch (e: any) {
      console.error('SignUpConfirm.Form.Register', e)
      enqueueSnackbar(e?.message || MESSAGES.WRONG_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  const handleOnChange = (e: any): void => {
    const value = e?.target?.value
    setFormData({ ...formData, code: value })
  }

  const handleCloseModal = (): void => {
    setIsEmailEditing(false)
    setIsNext(false)
    setIsEmailUpdateSuccess(false)
  }

  const onSubmit = async (data: any): Promise<void> => {
    const { accountId }: any = account?.myAccount ?? ''
    const currentEmail = account?.myAccount?.email ?? ''
    const { email } = data
    if (email === '' && currentEmail === '' && accountId === '') return
    try {
      const ICresponse = await APIs.getInvoiceAccount({
        accountId,
        email: currentEmail,
        AccessToken: authToken
      })
      if (ICresponse?.data?.Code === 0) {
        const user = await Auth.currentAuthenticatedUser()
        const response = await Auth.updateUserAttributes(user, {
          email
        })
        setFormData(data)
        if (response === SUCCESS) {
          enqueueSnackbar(MESSAGES.VERIFICATION_CODE_CHECK, {
            variant: 'success',
            autoHideDuration: 3000
          })
          setIsNext(!isNext)
        }
      }
    } catch (e: any) {
      const { response, message } = e
      const { data } = response
      enqueueSnackbar(data?.error || message || MESSAGES.SOMETHING_WRONG_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  return (

    <Dialog
      fullWidth
      open={open}
      onClose={handleCloseModal}
    >
      <Box p={3}>
        <Typography color="#000000" fontWeight={600} fontSize={24}>{
          isNext ? 'Enter Verification Code' : 'New Email Address'
        }</Typography>
        {!isEmailUpdateSuccess
          ? (
            <Box sx={{ width: '100%' }}>
              {!isNext && (
                <form method="post" onSubmit={handleSubmit(onSubmit)}>
                  <Box mt={4} mb={2}>
                    <FormLabel htmlFor="email">New Email address</FormLabel>
                    <Controller
                      name="email"
                      control={control}
                      render={({
                        field: { onChange, value, name }
                      }): JSX.Element => (
                        <OutlinedInput
                          name={name}
                          placeholder="mail@mail.com"
                          onChange={onChange}
                          value={value}
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                    {errors.email && (
                      <ErrorText>{errors.email.message}</ErrorText>
                    )}
                  </Box>

                  <Box mb={5}>
                    <FormLabel htmlFor="emailConfirm">
                      Confirm New Email address
                    </FormLabel>
                    <Controller
                      name="emailConfirm"
                      control={control}
                      render={({
                        field: { onChange, value, name }
                      }): JSX.Element => (
                        <OutlinedInput
                          name={name}
                          placeholder="mail@mail.com"
                          onChange={onChange}
                          value={value}
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                    {errors.emailConfirm && (
                      <ErrorText>{errors.emailConfirm.message}</ErrorText>
                    )}
                  </Box>
                  <Box my={2} textAlign="center">
                    <MButton
                      size="large"
                      variant="contained"
                      type="submit"
                      rounded="true"
                      disabled={!isValid}
                      texttransform="none"
                    >
                      Confirm New Email
                    </MButton>
                  </Box>
                </form>
              )}
              {isNext && (
                <>
                  <Box my={2}>
                    <FormLabel htmlFor="code">Code</FormLabel>
                    <Controller
                      name="code"
                      control={codeControl}
                      render={({
                        field: { onChange, value, name }
                      }): JSX.Element => (
                        <OutlinedInput
                          name={name}
                          placeholder="000000"
                          inputProps={{ maxLength: 6 }}
                          onKeyUpCapture={handleOnChange}
                          onChange={onChange}
                          value={value}
                          type="text"
                          fullWidth
                        />
                      )}
                    />
                    {codeError?.code && (
                      <ErrorText>{codeError.code.message}</ErrorText>
                    )}
                  </Box>
                  <Box
                    my={2}
                    textAlign="center"
                    sx={{
                      backgroundColor: '#EEF3F6',
                      padding: 1.2
                    }}
                  >
                    <Typography
                      display="inline"
                      sx={{
                        fontWeight: 600,
                        fontSize: '12px'
                      }}
                    >
                      Didn&apos;t get a code?{' '}
                    </Typography>
                    <Typography
                      display="inline"
                      sx={{
                        fontWeight: 400,
                        fontSize: '12px',
                        textDecoration: 'none'
                      }}
                    >
                      Request a new code by{' '}
                      <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={resendCode}>
                        clicking here.
                      </Link>
                    </Typography>
                  </Box>
                  <Box my={1} textAlign="center">
                    <MButton
                      size="large"
                      variant="contained"
                      type="submit"
                      rounded="true"
                      disabled={!isCodeValid}
                      texttransform="none"
                      onClick={handleVerifyEmail}
                      loading={isLoading}
                    >
                      Submit Code
                    </MButton>
                  </Box>
                </>
              )}
              <Box textAlign="center">
                <MButton
                  size="large"
                  variant="text"
                  type="button"
                  rounded="true"
                  onClick={() => setIsEmailEditing(false)}
                  texttransform="none"
                >
                  Cancel
                </MButton>
              </Box>
            </Box>
          )
          : (
            <>
              <Box textAlign="center">
                <div>
                  <SvgImage name="GreenCheckIcon" width={62} height={62} />
                  <Text heading="h1" fontSize="24px" align="center">
                    Email updated
                  </Text>
                  <Text
                    color="#5D6365"
                    fontSize="16px"
                    align="center"
                    margin="16px 0 20px"
                  >
                    Your email has been updated, if you need more help <br />
                    please{' '}
                    <Link onClick={() => { navigate(ROUTES_PATH.CONTACT) }}>
                      Contact Us
                    </Link>
                    . Please login again using your new email address.
                  </Text>
                </div>
              </Box>
            </>
          )}
      </Box>
    </Dialog>

  )
}

export const FormLabel = styled(InputLabel)`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4d515f;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 8px;
`
