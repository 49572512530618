import { AuthLayout } from 'components'
import { AuthWrapper } from 'containers/Auth'
import { Savings } from 'containers/Savings'

export const Saving: React.FC = () => {
  return (
    <AuthLayout>
      <AuthWrapper>
        <Savings isAuthenticated={false} />
      </AuthWrapper>
    </AuthLayout>
  )
}
