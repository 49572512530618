import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography
} from '@mui/material'

import { Text } from 'components'
import { FAQS_ITEMS } from 'config'

interface IFAQs {
  isAuthorized?: boolean
}

export const Faqs = ({ isAuthorized = true }: IFAQs): JSX.Element => {
  const [expanded, setExpanded] = useState<number | false>(false)

  const handleChange =
    (panel: number) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <Box bgcolor="#ffffff" my={5} p={4} borderRadius="4px">
      <Text
        color="#010B0E"
        fontSize="24px"
        fontWeight="600"
        align={isAuthorized ? 'left' : 'center'}
      >
        Frequently Asked Questions
      </Text>
      <Box my={2} textAlign={isAuthorized ? 'left' : 'center'}>
        <Typography color="#343C3E" fontSize="16px">
          If you have additional questions, please contact us at:&nbsp;
          <span style={{ color: '#007395' }}>(703) 721-3500&nbsp;</span>
          and press option 1.
          <span
            style={{
              display: 'block'
            }}
          >
            Monday-Friday 6:00 a.m. to 6:00 p.m. (except holidays)
          </span>
        </Typography>
      </Box>

      {FAQS_ITEMS[0].ul.map((item: any, index: number) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            sx={{
              py: 2,
              px: 3,
              bgcolor: '#EEF3F6'
            }}
            expandIcon={
              <IconButton
                size="small"
                disableRipple={true}
                sx={{ bgcolor: '#D7E3E9' }}
              >
                {expanded === index
                  ? <RemoveIcon sx={{ color: '#0092BD' }} />
                  : <AddIcon sx={{ color: '#0092BD' }} />
                }
              </IconButton>
            }
          >
            <Text color="#000" fontSize="16px" padding="0 40px 0 0">
              {item.title}
            </Text>
          </AccordionSummary>
          <AccordionDetails

            sx={{
              background: '#FBFBFB',
              py: 2,
              px: 3,
              borderLeft: '4px solid #66BED7',
              '.accordion-link': {
                color: '#007395'
              },
              ul: {
                paddingLeft: '0'
              },

              '.accordion-list': {
                listStyleType: 'none',
                padding: '0'
              },
              '.accordion-list::before': {
                content: "''",
                display: 'inline-block',
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: '#66BED7',
                marginRight: '8px'
              }
            }}
          >
            <Text color="#4D515F" fontSize="14px">
              <span dangerouslySetInnerHTML={{ __html: item.content }} />
            </Text>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  )
}
