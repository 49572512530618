import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import { Avatar, Box, Dialog, List, ListItem, Stack, Typography } from '@mui/material'
import type React from 'react'

interface EmailUnrecognizedDialogProps {
    open: boolean
    close: () => void
}

export const EmailUnrecognizedDialog: React.FC<EmailUnrecognizedDialogProps> = (props: EmailUnrecognizedDialogProps) => {
    return (
        <Dialog fullWidth open={props.open} onClose={() => { props.close() }}>
            <Box p={3} alignItems="center" justifyContent="center" >
                <Stack py={2} direction="column" gap={2} alignItems="center" justifyContent="center" borderBottom="solid 1px #D7E3E9">
                    <Box display="flex" justifyContent="right">
                        <Avatar sx={{ bgcolor: '#F3FBFD', width: 50, height: 50, p: 1 }}>
                            <EmailIcon fontSize='large' sx={{ color: '#9BB8C9' }} />
                        </Avatar>
                        <ErrorIcon sx={{ position: 'absolute', top: 90 }} color='error'></ErrorIcon>
                    </Box>
                    <Typography fontSize={24} color="#010B0E" fontWeight={600} textAlign="center">Email not recognized</Typography>
                    <Typography fontSize={16} color="#010B0E" textAlign="center">It looks like the email you provided is either not recognized or on file. Don&apos;t worry, though, there is still plenty you can do:</Typography>
                </Stack>
                <List sx={{
                    listStyleType: 'disc',
                    p: 3,
                    fontSize: 14,
                    color: '#5D6365'
                }}
                >
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography fontSize={14}>You can start a chat with a live agent by clicking on the chat bubble at the bottom right of the screen.</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography fontSize={14}>You can send us an email at <Typography fontSize={14} display="inline" color="#007395" sx={{ textDecoration: 'underline' }}>billing@alexrenew.com</Typography> so that we can update the email address on your account.</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography fontSize={14}>You can call us at  <Typography fontSize={14} display="inline" color="#007395">(703) 721-3500</Typography> so that we can update the email address on your account.</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography fontSize={14}>If you just want to pay your bill, please head back to the main page and click on the “One-Time Payment” button.</Typography>
                    </ListItem>
                </List>
            </Box>
        </Dialog>
    )
}
