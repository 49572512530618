import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Link,
  Typography
} from '@mui/material'
import { SvgImage, Text } from 'components'
import { BILLING_QUESTIONS, ROUTES_PATH } from 'config'
import type React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { Wrapper } from './BillingHistory'

export const BillingQuestions: React.FC = () => {
  const [expanded, setExpanded] = useState<number | false>(false)
  const handleChange =
    (panel: number) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }
  const isMobile = useMobileBreakpoints()
  const navigate = useNavigate()

  return (
    <Box>
      <Wrapper>
        <Typography color="#010B0E" fontSize={18} fontWeight={600} my={2}>
          Frequently Asked Billing Questions
        </Typography>
        <Box>
          {BILLING_QUESTIONS.map((item, index) => (
            <Accordion
              key={index}
              expanded={expanded === index}
              onChange={handleChange(index)}
              sx={{
                backgroundColor: '#F7F9FB',
                padding: isMobile ? 1 : 2
              }}
            >
              <AccordionSummary
                expandIcon={
                  <IconButton
                    size="small"
                    sx={{
                      display: 'flex',
                      bgcolor: '#D7E3E9',
                      '&:hover': { opacity: 1, bgcolor: '#D7E3E9' }
                    }}
                  >
                    {expanded === index
                      ? (<RemoveIcon sx={{ color: '#0092BD' }} />)
                      : (<AddIcon sx={{ color: '#0092BD' }} />)}
                  </IconButton>
                }
              >
                <Typography color="#010B0E" fontSize={isMobile ? 14 : 16} mr={2}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  '.accordion-link': {
                    color: '#007395'
                  },
                  '.accordion-list': {
                    listStyleType: 'none',
                    padding: '0'
                  },
                  '.accordion-list::before': {
                    content: "''",
                    display: 'inline-block',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: '#66BED7',
                    marginRight: '8px'
                  }
                }}
              >
                <Text color="#000" fontSize="14px" margin="0 0 16px">
                  <span dangerouslySetInnerHTML={{ __html: item.content }} />
                </Text>
              </AccordionDetails>
            </Accordion>
          ))}
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" py={isMobile ? 2 : 5} >
            <Typography fontSize={18} fontWeight={600} color="#010B0E">Have any additional questions?
              <Link mt={isMobile ? 1 : 0} ml={isMobile ? 0 : 1} sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(ROUTES_PATH.CONTACT) }}>
                Contact Us
              </Link>
            </Typography>
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} mt={isMobile ? 2 : 0}>
              <Typography fontSize={18} fontWeight={600} color="#010B0E">Find us at:</Typography>
              <Box display="flex" flexDirection="column" gap={2} px={isMobile ? 0 : 3} py={isMobile ? 3 : 0} borderRight={isMobile ? 0 : 'solid 1px #D7E3E9'} borderBottom={isMobile ? 'solid 1px #D7E3E9' : 0}>
                <FindUsLink href="https://facebook.com/alexandriarenew" icon="FBIcon" title="/alexandriarenew" />
                <FindUsLink href="https://twitter.com/AlexandriaRenew" icon="TWIcon" title="@AlexandriaRenew" />
                <FindUsLink href="https://www.linkedin.com/company/alexandria-renew-enterprises" icon="InIcon" title="Alexandria Renew Enterprises" />
              </Box>
              <Box display="flex" flexDirection="column" gap={2} px={isMobile ? 0 : 3} pt={isMobile ? 3 : 0}>
                <FindUsLink href="$" icon="PhoneIcon" title="(703) 721-3500 (Option 1)" />
                <FindUsLink href="mailto:billing@alexrenew.com" icon="EmailIcon" title="billing@alexrenew.com" />
              </Box>
            </Box>

          </Box>
        </Box>
      </Wrapper >
    </Box >
  )
}

interface FindUsLinkProps {
  href: string
  icon: string
  title: string
}

const FindUsLink: React.FC<FindUsLinkProps> = (props: FindUsLinkProps) => {
  return <Link href={props.href} sx={{ textDecoration: 'none' }} target='_blank' rel="noopener noreferrer">
    <Box display="flex" gap={1}>
      <SvgImage name={props.icon} width={24} height={24} />
      <Typography color="#000000" fontSize={14}>{props.title}</Typography>
    </Box>
  </Link>
}
