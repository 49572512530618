import { Navigate, useLocation } from 'react-router-dom'

import { ROUTES_PATH, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import { useLocalStorage } from 'utils'

export const AuthorizedRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { checkingUser } = useAuth()
  const location = useLocation()
  const [authToken] = useLocalStorage(
    USER_TOKEN,
    ''
  )

  if (!checkingUser && authToken === '') {
    return (
      <Navigate to={ROUTES_PATH.SIGNIN} state={{ from: location }} replace />
    )
  }
  return children
}
