import { Box, Dialog, Typography, styled } from '@mui/material'
import { MButton, SvgImage, Text } from 'components'
import { ROUTES_PATH } from 'config'
import type React from 'react'
import { useNavigate } from 'react-router-dom'

interface PasswordNotificationModalProps {
  open: boolean
}

const PasswordNotificationModal: React.FC<PasswordNotificationModalProps> = ({
  open
}) => {
  const navigate = useNavigate()
  const handleBack = (): void => {
    navigate(ROUTES_PATH.SIGNIN)
  }
  return (
    <Dialog fullWidth open={open} closeAfterTransition>
      <ModalHeader>
        <Typography fontSize={'18px'}>Password Notification</Typography>
      </ModalHeader>
      <ModalContent p={5}>
        <SvgImage name="GreenCheckIcon" width={250} height={62} />
        <Text
          color="#1F1F1F"
          fontSize="16px"
          align="center"
          margin="30px 0 30px"
        >
          Your password has been successfully changed!
        </Text>

        <MButton
          type="button"
          variant="contained"
          size="large"
          rounded="true"
          sx={{ width: '200px' }}
          texttransform="none"
          onClick={handleBack}
        >
          Back to log in
        </MButton>
      </ModalContent>
    </Dialog>
  )
}

export default PasswordNotificationModal


const ModalHeader = styled(Box)`
  border-bottom: 1px solid #b1bada;
  padding: 15px;
`

const ModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
