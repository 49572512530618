import PinDropIcon from '@mui/icons-material/PinDrop'
import { Box, List, ListItem, Typography } from '@mui/material'
import { MButton } from 'components'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { Wrapper } from './BillingHistory'

export const PaymentLocation: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  const handleFindLocation = (): void => {
    window.open(
      'https://www.checkfreepay.com/en/payment-locator.html',
      '_blank'
    )
  }

  return (
    <Wrapper>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="#F7F9FB" p={isMobile ? 3 : 5} m={isMobile ? 1 : 5} gap={2}>
        <Box display="flex" alignItems="center" gap={1}>
          <PinDropIcon sx={{ color: '#67D2DF' }} />
          <Typography fontSize={18} fontWeight={600} color="#010B0E">Other Payment Options</Typography>
        </Box>
        <List
          sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
              color: '#5D6365'
            }
          }}
        >
          <ListItem sx={{ display: 'list-item', marginBottom: '24px' }}>
            <Typography color="#5D6365" fontSize={isMobile ? 14 : 16}>
              Mail check or money order to AlexRenew at PO BOX 26428,
              ALEXANDRIA VA 22313-6428.
            </Typography>
          </ListItem>
          <ListItem sx={{ display: 'list-item' }}>
            <Typography color="#5D6365" fontSize={isMobile ? 14 : 16}>
              Walk-in: AlexRenew customers have the ability to walk in to
              several locations to pay their bill. Customers can search the
              locations by zip code using the link below. Please be advised
              that most retail stores charge a fee to process a payment.
            </Typography>
          </ListItem>
        </List>
        <MButton variant="contained" rounded="true" size="large" texttransform="none" onClick={handleFindLocation}>
          Find Walk-In Locations
        </MButton>
      </Box>

    </Wrapper>
  )
}
