import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import WidgetsIcon from '@mui/icons-material/Widgets'
import { AccordionDetails, AccordionSummary, Box, IconButton, Typography, type SvgIconTypeMap } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import { type OverridableComponent } from '@mui/material/OverridableComponent'
import { InvoiceCloudFrame } from 'components/domain'
import { MButton, Text } from 'components/ui'
import { MESSAGES, PAY_TOOLS } from 'config'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IQuickLinks {
    id: number
    title: string
    Icon: OverridableComponent<SvgIconTypeMap<any, 'svg'>>
    page?: number
    link?: string
}

const QuickLinks = (): JSX.Element => {
    const isMobile = useMobileBreakpoints()
    const { account } = useAuth()
    const { enqueueSnackbar } = useSnackbar()

    const handleItem = (item: IQuickLinks): void => {
        if ((item.link != null) && item.title === 'Payment Plan') {
            window.open(item.link, '_blank')
        }
        if (item.title === 'View Bill') {
            handleDownloadBill()
        }
    }
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const handleAccordionClick = (): void => {
        setIsExpanded(!isExpanded)
    }
    const handleDownloadBill = (): void => {
        if ((account?.myAccount?.billDisplayURL) !== null) {
            window.open(account?.myAccount?.billDisplayURL, '_blank', 'noopener,noreferrer')
        } else {
            enqueueSnackbar(MESSAGES.BILL_PDF_ERROR, {
                variant: 'error',
                autoHideDuration: 5000
            })
        }
    }
    return (isMobile
        ? (
            <>
                <Box mt={2}>
                    <Accordion onClick={handleAccordionClick}>
                        <AccordionSummary
                            expandIcon={<IconButton disableRipple={true} size="small" sx={{ bgcolor: '#D7E3E9' }}>{isExpanded ? <RemoveIcon sx={{ color: '#0092BD' }} /> : <AddIcon sx={{ color: '#0092BD' }} />}</IconButton>}
                        >
                            <Box display="flex" gap={1} p={0.5}>
                                <WidgetsIcon sx={{ color: '#0092BD' }} />
                                <Typography >Quick Links</Typography>
                            </Box>

                        </AccordionSummary>
                        <AccordionDetails>
                            {PAY_TOOLS.map((tool) => (
                                <InvoiceCloudFrame key={tool.id} page={tool.page}>
                                    <MButton
                                        key={tool.id}
                                        variant="contained"
                                        size="medium"
                                        texttransform='none'
                                        bgcolor='#E7EEF2'

                                        sx={{
                                            color: '#010B0E',
                                            paddingX: 2,
                                            paddingY: 1.5,
                                            marginBottom: 1.5,
                                            justifyContent: 'left'
                                        }}
                                        fullWidth
                                        startIcon={<tool.Icon sx={{ color: '#0092BD' }} />}
                                        onClick={() => {
                                            handleItem(tool)
                                        }}
                                    >
                                        {tool.title}
                                    </MButton>
                                </InvoiceCloudFrame>
                            ))}
                        </AccordionDetails>

                    </Accordion>
                </Box>
            </>
        )
        : (
            <>
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    zIndex={999}
                    py={3}
                    px={5}
                    bgcolor="#F7F9FB"
                    border="2px solid #E7EEF2"
                >
                    <Text color="#5D6365" fontSize="12px">
                        QUICK LINKS
                    </Text>
                    <Box display="flex" flexDirection="row" gap={3}>
                        {PAY_TOOLS.map((tool) => (
                            <InvoiceCloudFrame key={tool.id} page={tool.page}>
                                <MButton
                                    key={tool.id}
                                    variant="contained"
                                    texttransform="none"
                                    rounded="true"
                                    size="large"
                                    sx={{
                                        background: '#E7EEF2',
                                        boxShadow: 'none',
                                        color: '#010B0E',
                                        '&:hover': {
                                            color: '#fff'
                                        }
                                    }}
                                    startIcon={<tool.Icon sx={{ color: '#0092BD' }} />}
                                    onClick={() => {
                                        handleItem(tool)
                                    }}
                                >
                                    {tool.title}
                                </MButton>
                            </InvoiceCloudFrame>
                        ))}
                    </Box>
                </Box>
            </>
        ))
}

export default QuickLinks
