import { Box, FormControlLabel, FormLabel, Link, List, ListItem, OutlinedInput, Radio, RadioGroup, Typography } from '@mui/material'
import type React from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { ErrorText, MButton, ProgressLoaders } from 'components'
import { useAuth } from 'context'
import { useEffect, useState } from 'react'
import { useLocalStorage } from 'utils'
import { MESSAGES, USER_TOKEN } from 'config'
import { APIs } from 'services'
import { useSnackbar } from 'notistack'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
export interface IDonationForm {
    name: string
    accountNumber: string
    serviceAddress: string
    emailAddress: string
    contactNumber: string
    requestType: string
    amount: string
}

export const DonationsContainer: React.FC = () => {
    const isMobile = useMobileBreakpoints()
    const { account, currentAccount, isAdmin, userEmail, getAccount } = useAuth()
    const [authToken] = useLocalStorage(USER_TOKEN, '')
    const [prevAccountId, setPrevAccountId] = useState<string>('')
    const { enqueueSnackbar } = useSnackbar()
    const schema = yup
        .object({
            name: yup.string().required(),
            accountNumber: yup.string().required(),
            serviceAddress: yup.string().required(),
            emailAddress: yup.string().email().required(),
            contactNumber: yup.string().required(),
            requestType: yup.string().required(),
            amount: yup.number().required()

        })
        .required()
    const { handleSubmit, control, formState: { errors, isValid } } = useForm<IDonationForm>({
        mode: 'all',
        defaultValues: {
            name: account?.myAccount?.personName,
            accountNumber: account?.myAccount?.accountId,
            serviceAddress: account?.myAccount?.serviceAddresses[0]?.serviceAddress,
            emailAddress: account?.myAccount?.email,
            contactNumber: account?.myAccount?.mobileNumber ?? account?.myAccount?.officeNumber ?? account?.myAccount?.alternateNumber,
            requestType: '',
            amount: ''
        },
        resolver: yupResolver(schema)
    })


    useEffect(() => {
        if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
            return
        }
        if (currentAccount?.accountId !== null) {
            void getAccount({
                AccessToken: authToken,
                accountId: currentAccount?.accountId,
                admin: isAdmin,
                email: userEmail
            })
        }
        setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, currentAccount])

    const onSubmit = async (data: IDonationForm): Promise<void> => {
        try {
            const body = {
                ...data,
                AccessToken: authToken
            }
            await APIs.sendDonations(body)
            enqueueSnackbar(MESSAGES.DONATION_SENT_SUCCESS, {
                variant: 'success'
            })
        } catch (e) {
            enqueueSnackbar(MESSAGES.FORM_SUBMIT_ERROR, {
                variant: 'error'
            })
        }
    }


    return (
        <>
            {isMobile && <QuickLinks />}
            <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} bgcolor="#FFFFFF" gap={5}>
                {account !== null
                    ? <Box flex={1} display="flex" flexDirection="column" gap={3} p={3}>
                        <Typography color="#010B0E" fontSize={24} fontWeight={600}>Lifeline Emergency Assistance Program (LEAP) Donations</Typography>
                        <Typography color="#5D6365" fontSize={16} textAlign="justify">With your help, AlexRenew&apos;s Lifeline Emergency Assistance Program (LEAP) will distribute financial aid to customers in need of
                            assistance with their wastewater bills. Support your neighbors today by making a tax-deductible contribution, and rest assured
                            that 100% of your donation goes directly to AlexRenew customers in need. To learn more, visit <Link href="https://alexrenew.com/LEAP">Lifeline Emergency Assistance Program (LEAP)</Link>.
                        </Typography>
                        <Typography color="#5D6365" fontSize={16} textAlign="justify">
                            By selecting a frequency and donation amount in the boxes below, you authorize AlexRenew to collect the non-refundable donation amount, which will appear as “donated” on your next bill.
                        </Typography>
                        <Box component="form" display="flex" flexDirection="column" gap={2}>
                            <Controller name="name" control={control} render={({ field: { onChange, value, name } }): JSX.Element => (
                                <Box display="flex" flexDirection="column" gap={0.5}>
                                    <FormLabel sx={{ fontSize: 12, letterSpacing: 2 }}>NAME *</FormLabel>
                                    <OutlinedInput
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        fullWidth
                                    />
                                    {(errors.name != null) && <ErrorText>{errors.name.message}</ErrorText>}
                                </Box>
                            )}
                            />
                            <Controller name="accountNumber" control={control} render={({ field: { onChange, value, name } }): JSX.Element => (
                                <Box display="flex" flexDirection="column" gap={0.5}>
                                    <FormLabel sx={{ fontSize: 12, letterSpacing: 2 }}>ACCOUNT NUMBER *</FormLabel>
                                    <OutlinedInput
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        fullWidth
                                    />
                                    {(errors.accountNumber != null) && <ErrorText>{errors.accountNumber.message}</ErrorText>}
                                </Box>
                            )}
                            />
                            <Controller name="serviceAddress" control={control} render={({ field: { onChange, value, name } }): JSX.Element => (
                                <Box display="flex" flexDirection="column" gap={0.5}>
                                    <FormLabel sx={{ fontSize: 12, letterSpacing: 2 }}>SERVICE ADDRESS *</FormLabel>
                                    <OutlinedInput
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        fullWidth
                                    />
                                    {(errors.serviceAddress != null) && <ErrorText>{errors.serviceAddress.message}</ErrorText>}
                                </Box>
                            )}
                            />
                            <Controller name="emailAddress" control={control} render={({ field: { onChange, value, name } }): JSX.Element => (
                                <Box display="flex" flexDirection="column" gap={0.5}>
                                    <FormLabel sx={{ fontSize: 12, letterSpacing: 2 }}>EMAIL ADDRESS *</FormLabel>
                                    <OutlinedInput
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        fullWidth
                                    />
                                    {(errors.emailAddress != null) && <ErrorText>{errors.emailAddress.message}</ErrorText>}
                                </Box>
                            )}
                            />
                            <Controller name="contactNumber" control={control} render={({ field: { onChange, value, name } }): JSX.Element => (
                                <Box display="flex" flexDirection="column" gap={0.5}>
                                    <FormLabel sx={{ fontSize: 12, letterSpacing: 2 }}>CONTACT NUMBER *</FormLabel>
                                    <OutlinedInput
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        type="text"
                                        fullWidth
                                    />
                                    {(errors.contactNumber != null) && <ErrorText>{errors.contactNumber.message}</ErrorText>}
                                </Box>
                            )}
                            />
                            <Controller name="requestType" control={control} render={({ field: { onChange, value, name } }): JSX.Element => (
                                <Box display="flex" flexDirection="column" gap={0.5}>
                                    <RadioGroup row name={name} value={value} onChange={onChange} >
                                        <FormControlLabel value="One Time" control={<Radio />} label="One Time" />
                                        <FormControlLabel value="Recurring" control={<Radio />} label="Recurring" />
                                        <FormControlLabel value="Cancel" control={<Radio />} label="Cancel" />
                                    </RadioGroup>
                                    {(errors.requestType != null) && <ErrorText>{errors.requestType.message}</ErrorText>}
                                </Box>
                            )}
                            />
                            <Controller name="amount" control={control} render={({ field: { onChange, value, name } }): JSX.Element => (
                                <Box display="flex" flexDirection="column" gap={0.5}>
                                    <FormLabel sx={{ fontSize: 12, letterSpacing: 2 }}>AMOUNT *</FormLabel>
                                    <RadioGroup row name={name} value={value} onChange={onChange}>
                                        <FormControlLabel value="1" control={<Radio />} label="$1" />
                                        <FormControlLabel value="3" control={<Radio />} label="$3" />
                                        <FormControlLabel value="5" control={<Radio />} label="$5" />
                                    </RadioGroup>
                                    {(errors.amount != null) && <ErrorText>{errors.amount.message}</ErrorText>}
                                </Box>
                            )}
                            />
                            <Box mt={2}>
                                <MButton
                                    variant="contained"
                                    rounded="true"
                                    texttransform="none"
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={!isValid}
                                    size="large"
                                >
                                    Submit Form
                                </MButton>
                            </Box>
                        </Box>
                    </Box>
                    : <ProgressLoaders />}
                <Box flex={1} display="flex" flexDirection="column" gap={3} bgcolor="#FFFFFF" p={3}>
                    <Typography color="#010B0E" fontSize={24} fontWeight={600}>Lifeline Emergency Assistance Program (LEAP) Assistance</Typography>
                    <Typography color="#5D6365" fontSize={16} textAlign="justify">LEAP is designed to help customers experiencing difficulty paying their sewer bills. The program offers one-time emergency assistance for eligible AlexRenew customers.</Typography>
                    <Typography color="#010B0E" fontSize={18} fontWeight={600}>Eligibility criteria:</Typography>
                    <List sx={{ listStyleType: 'disc', pl: '10px' }}>
                        <ListItem sx={{ display: 'list-item', pl: '0' }}>
                            <Typography>AlexRenew account holders</Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', pl: '0' }}>
                            <Typography>60+ day past-due balance of $100 ($50 if you&apos;re over 62)</Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', pl: '0' }}>
                            <Typography>Eligible customers receive up to $350 a year</Typography>
                        </ListItem>
                        <ListItem sx={{ display: 'list-item', pl: '0' }}>
                            <Typography>Customers may apply starting in early 2024</Typography>
                        </ListItem>
                    </List>
                    <Typography>If you think you may qualify for the program, email <Link href='mailto:billing@alexrenew.com'>billing@alexrenew.com</Link>.</Typography>
                </Box>
            </Box>
        </>
    )
}
