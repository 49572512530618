import { Box, Button, Typography, styled } from '@mui/material'
import { InvoiceCloudFrame, MButton, ProgressLoaders, Text } from 'components'
import { USER_TOKEN } from 'config'
import { ROUTES_PATH } from 'config/routes'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

export const PowerBill: React.FC = () => {
  const { account, currentAccount, getAccount, isAdmin, userEmail } = useAuth()
  const isMobile = useMobileBreakpoints()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])
  return (
    <>
      {account !== null
        ? <Wrapper height="100%">
          <Box px={3} py={5}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              pb={3}
            >
              <Box display="flex" flexDirection="column" gap={1}>
                <Text color="#000000" fontSize="18px" fontWeight="600">
                  Wastewater bill
                </Text>
                <Text color="#5D6365" fontSize="12px">
                  Account Number: {account?.myAccount.accountId}
                </Text>
              </Box>
              {!isMobile && <Box display="flex">
                <InvoiceCloudFrame page={1}>
                  <MButton variant="text" texttransform="none">
                    <Text color="#007395" fontSize="16px" align="right">
                      Pay Bill
                    </Text>
                  </MButton>
                </InvoiceCloudFrame>
                <Link to={ROUTES_PATH.BILLING}>
                  <MButton variant="text" texttransform="none">
                    <Text color="#007395" fontSize="16px" align="right">
                      View Details
                    </Text>
                  </MButton>
                </Link>
              </Box>}
            </Box>
            <Box flexDirection={isMobile ? 'column' : 'row'} display="flex">
              <Box flex={1} display="flex" flexDirection="column" gap={1} pt={2} pb={3} borderBottom={isMobile ? 'solid 2px #E7EEF2' : '0'}>
                <Typography color="#010B0E" fontSize={12} letterSpacing={2}>
                  DETAILS
                </Typography>
                <Box display="flex" flexDirection={isMobile ? 'row' : 'column'} justifyContent="space-between" alignItems={isMobile ? 'center' : 'left'}>
                  <Typography color="#5D6365" fontSize={12}>
                    Previous Bill Adjustments:
                  </Typography>
                  <Typography color="#010B0E" fontWeight={600} fontSize={18}>
                    {((account?.myAccount?.previousBalance) != null) && formatValue(account?.myAccount?.previousBalance)}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection={isMobile ? 'row' : 'column'} justifyContent="space-between" alignItems={isMobile ? 'center' : 'left'}>
                  <Typography color="#5D6365" fontSize={12}>
                    Previous Balance Past Due:
                  </Typography>
                  <Typography color="#010B0E" fontWeight={600} fontSize={18}>
                    {((account?.myAccount?.lateFees) != null) && formatValue(account?.myAccount?.lateFees)}
                  </Typography>
                </Box>
              </Box>
              <Box flex={1} display="flex" flexDirection="column" pt={isMobile ? 2 : 0} >
                <Box display="flex" flexDirection={isMobile ? 'row' : 'column'} justifyContent="space-between" mb={2}>
                  <Typography color="#010B0E" fontSize={12} letterSpacing={2}>
                    BALANCE DUE
                  </Typography>
                  <Typography color="#5D6365" fontSize={12}>
                    Due Date:{' '}
                    {((account?.myAccount?.dueDate) != null) && dayjs(account?.myAccount?.dueDate).format('MMM D, YYYY')}
                  </Typography>
                </Box>
                <Typography fontSize={isMobile ? '30px' : '52px'} bgcolor="#E7EEF2" py={1} borderRadius={1} border="solid 1px #B9CDD9" width="100%" textAlign="center">
                  {((account?.myAccount?.accountBalance) != null) && formatValue(account?.myAccount?.accountBalance)}
                </Typography>
              </Box>
              {isMobile && <Box mt={3}>
                <InvoiceCloudFrame page={1}>
                  <Button size='small' fullWidth variant="contained" sx={{ borderRadius: 10, paddingY: 2, fontSize: 16, textTransform: 'none', boxShadow: 0 }}>
                    Pay Bill
                  </Button>
                </InvoiceCloudFrame>
                <Link to={ROUTES_PATH.BILLING}>
                  <Button size='small' fullWidth variant="outlined" sx={{ borderRadius: 10, paddingY: 2, fontSize: 16, textTransform: 'none', boxShadow: 0, marginTop: 2 }}>
                    View Details
                  </Button>
                </Link>
              </Box>}
            </Box>
          </Box>
        </Wrapper>
        : <ProgressLoaders height='100%' />}
    </>
  )
}

const Wrapper = styled(Box)({
  background: '#ffffff',
  borderRadius: '4px'
})
