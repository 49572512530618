import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import type React from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { UsageView } from './components'

export const Usage: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  return (
    <>
      {isMobile && <QuickLinks />}
      <UsageView />
    </>

  )
}
