import { Box } from '@mui/material'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { FindUs, Form } from './components'

export const Contact: React.FC = () => {
  const isMobile = useMobileBreakpoints()
  return (
    <>
      {isMobile && <QuickLinks />}
      <Box display="flex" mt={3} flexDirection={isMobile ? 'column' : 'row'} p={isMobile ? 3 : 4} bgcolor="#ffffff" gap={5}>
        <Form />
        <FindUs isAuthenticated />
      </Box>
    </>


  )
}
