import { Box } from '@mui/material'
import { useEffect } from 'react'

const Chat: React.FC = () => {
    const { REACT_APP_IS_SYSTEM_UNDER_MAINTENANCE } = process.env
    useEffect(() => {
        const comm100Script = document.createElement('script')
        comm100Script.type = 'text/javascript'
        comm100Script.async = true
        comm100Script.innerHTML = `
            var Comm100API=Comm100API||{};(function(t){function e(e){var a=document.createElement("script"),c=document.getElementsByTagName("script")[0];a.type="text/javascript",a.async=!0,a.src=e+t.site_id,c.parentNode.insertBefore(a,c)}t.chat_buttons=t.chat_buttons||[],t.chat_buttons.push({code_plan:"5f4addef-66f5-4fcf-bb3f-7eca62f7e883",div_id:"comm100-button-5f4addef-66f5-4fcf-bb3f-7eca62f7e883"}),t.site_id=75000117,t.main_code_plan="5f4addef-66f5-4fcf-bb3f-7eca62f7e883",e("https://vue.comm100.com/livechat.ashx?siteId="),setTimeout(function(){t.loaded||e("https://standby.comm100vue.com/livechat.ashx?siteId=")},5e3)})(Comm100API||{})
        `
        document.body.appendChild(comm100Script)
    }, [])

    return (
        <>
            {REACT_APP_IS_SYSTEM_UNDER_MAINTENANCE === 'false' &&
                <Box position="fixed" bottom={20} right={20} zIndex={10}>
                    <div id="comm100-button-5f4addef-66f5-4fcf-bb3f-7eca62f7e883" />
                </Box>
            }
        </>
    )
}

export default Chat
