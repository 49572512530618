import { Box, Grid } from '@mui/material'
import LoginLeft from 'assets/images/login-left.png'
import { AuthLayout, Text } from 'components'
import { WELCOME_DESCRIPTION, WELCOME_TITLE } from 'config'
import { AuthMain, AuthWrapper } from 'containers/Auth'
import { Form } from './component'

interface IAuthTitle {
  title: string
}

export const AuthTitle = ({ title }: IAuthTitle): JSX.Element => {
  return (
    <Text color="#010B0E" fontSize="24px" fontWeight="600" margin="0 0 24px">
      {title}
    </Text>
  )
}

export const Pay: React.FC = () => {
  return (
    <AuthLayout
      title={WELCOME_TITLE}
      desc={WELCOME_DESCRIPTION}
      isAuthPage={true}
    >
      <AuthWrapper>
        <Grid container justifyContent="center" mt={7}>
          <Grid item sm={0} md={6}>
            <Box
              sx={{
                backgroundSize: 'cover',
                backgroundImage: `url("${LoginLeft}")`,
                height: '100%'
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AuthMain isRounded={true}>
              <AuthTitle title="Pay Bill" />
              <Form />

            </AuthMain>
          </Grid>

        </Grid>

      </AuthWrapper>
    </AuthLayout>
  )
}
