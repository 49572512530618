import ErrorIcon from '@mui/icons-material/Error'
import PaymentsIcon from '@mui/icons-material/Payments'
import {
  Box,
  CircularProgress,
  Grid,
  Link,
  Radio,
  Typography,
  styled
} from '@mui/material'
import { InvoiceCloudFrame, MButton, ProgressLoaders, Text } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { MESSAGES, PAYMENT_PLAN_URL, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { APIs } from 'services'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { EditInformationForm, type IMailingAddress } from './component/Form'

export interface IUserValue {
  name?: string
  officeNumber?: string
  mobileNumber?: string
  mailingAddress?: string
  email?: string
  balance?: string
  alteredEmail?: string
  serviceAddress?: string[]
}

export const Account: React.FC = () => {
  const { account, updateMailAddress, currentAccount, isAdmin, userEmail, getAccount, handleCurrentAccount } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [active, setActive] = useState<number>(0)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [userValue, setUserValue] = useState<IUserValue>()
  const [defaultAccountId, setDefaultAccountId] = useState<any>(null)
  const [mailingAddress, setMailingAddress] = useState<IMailingAddress>()
  const [portalPhone, setPortalPhone] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  useEffect(() => {
    if (account) {
      const serviceAddresses = account?.myAccount?.serviceAddresses.map(item => item.serviceAddress)
      setUserValue({
        name: account?.myAccount?.personName,
        officeNumber: account?.myAccount?.alternateNumber,
        mobileNumber: account?.myAccount?.mobileNumber,
        mailingAddress: `${account?.myAccount?.mailingAddress?.addressLine1}, ${account?.myAccount?.mailingAddress?.city}, ${account?.myAccount?.mailingAddress?.state} ${account?.myAccount?.mailingAddress?.postal}`,
        email: account?.myAccount?.email,
        balance:
          account?.myAccount?.accountBalance &&
          formatValue(account?.myAccount.accountBalance),
        alteredEmail: 'ND',
        serviceAddress: serviceAddresses
      })
    }
  }, [account])

  useEffect(() => {
    if (account && !defaultAccountId) {
      setDefaultAccountId(account?.myAccount.defaultAccountId)
    }
  }, [account, defaultAccountId])


  const handleCheckBox = async (item: any): Promise<void> => {
    try {
      const body: any = {
        AccessToken: authToken,
        accountId: account?.myAccount.accountId,
        admin: isAdmin,
        email: userEmail
      }
      body.updates = [
        {
          object: 'DEFAULTACCOUNT',
          value: item.accountId
        }
      ]
      await APIs.updateUser(body)
      enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
        variant: 'success',
        autoHideDuration: 3000
      })
      setDefaultAccountId(item.accountId)
      handleCurrentAccount({ accountId: item.accountId ?? '', premiseId: item?.premiseId ?? '' })
      getAccount({
        AccessToken: authToken,
        accountId: item.accountId
      })
    } catch (e) {
      enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }
  }

  const handleEdit = async (): Promise<void> => {
    setIsLoading(true)

    if (isEditing && mailingAddress) {
      try {
        const response = await updateMailAddress({
          AccessToken: authToken,
          addressLine1: mailingAddress.addressLine1,
          addressLine2: mailingAddress.addressLine2,
          postal: mailingAddress.postal,
          city: mailingAddress.city,
          state: mailingAddress.state
        })
        if (response && response?.status === 'success') {
          enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
            variant: 'success',
            autoHideDuration: 3000
          })
          setUserValue({
            ...userValue,
            mailingAddress: `${mailingAddress.addressLine1}, ${mailingAddress.city}, ${mailingAddress.state}, - ${mailingAddress.postal}`
          })
        }
      } catch (e: any) {
        if (e && e?.response?.data?.code === 'UPDATE_LIMIT_EXCEEDED') {
          enqueueSnackbar(MESSAGES.UPDATE_LIMIT_EXCEEDED, {
            variant: 'error',
            autoHideDuration: 3000
          })
        } else {
          enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
            variant: 'error',
            autoHideDuration: 3000
          })
        }
      }
    }

    if (isEditing && portalPhone) {
      try {
        const response = await APIs.updateUser({
          AccessToken: authToken,
          accountId: account?.myAccount.accountId ?? '',
          admin: isAdmin,
          email: userEmail,
          updates: [
            {
              object: 'PORTALPHONE',
              value: portalPhone
            }
          ]
        })
        if (response.status === 1) {
          enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_SUCCESS, {
            variant: 'success',
            autoHideDuration: 3000
          })
          setUserValue({
            ...userValue,
            mobileNumber: portalPhone
          })
        }
      } catch (e) {
        enqueueSnackbar(MESSAGES.ACCOUNT_UPDATED_ERROR, {
          variant: 'error',
          autoHideDuration: 3000
        })
      }
    }

    setIsLoading(false)
    setIsEditing(!isEditing)
  }

  const handleMailingAddressChange = (mailingAddress: IMailingAddress): void => {
    setMailingAddress(mailingAddress)
  }

  const handlePortalPhoneChange = (portalPhone: string): void => {
    setPortalPhone(portalPhone)
  }

  return (
    <>
      {isMobile && <QuickLinks />}
      <Grid container spacing={2} mt={0}>
        <Grid item sm={12} md={8}>
          <Wrapper gap={2}>
            <BoxWrapper>
              <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                <Typography color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight="600">
                  General Information
                </Typography>
                {!isMobile && <MButton
                  rounded="true"
                  variant={isEditing ? 'contained' : 'outlined'}
                  texttransform="none"
                  onClick={handleEdit}
                  startIcon={
                    isLoading && (
                      <CircularProgress color="inherit" size={20} />
                    )
                  }
                >
                  {isEditing ? 'Save Changes' : 'Edit Information'}
                </MButton>}
              </Box>

              {userValue
                ? (
                  isEditing
                    ? <EditInformationForm
                      onMailingAddressChange={handleMailingAddressChange}
                      onPortalPhoneChange={handlePortalPhoneChange}
                      userValue={userValue}
                    />

                    : (
                      <GeneralInformation userValue={userValue} />
                    )
                )
                : (
                  <ProgressLoaders height="100%" />
                )}

              {isMobile && <Box mt={3}>
                <MButton
                  rounded="true"
                  variant={isEditing ? 'contained' : 'outlined'}
                  texttransform="none"
                  onClick={handleEdit}
                  size="large"
                  fullWidth
                  startIcon={
                    isLoading && (
                      <CircularProgress color="inherit" size={20} />
                    )
                  }
                >
                  {isEditing ? 'Save Changes' : 'Edit Information'}
                </MButton>
              </Box>}

            </BoxWrapper>

            <BoxWrapper>
              <Typography color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight="600">
                Service Address
              </Typography>
              <Box my={2}>
                <Text color="#4d515f" fontSize="16px" inline="true">
                  Please select a service address from the list below to be
                  displayed by default when you log in to your account portal.
                  If you do not see your service address listed below, please
                  contact &nbsp;
                </Text>
                <Text color="#007395" fontSize="16px" inline="true">
                  (703) 721-3500
                </Text>
                <Text color="#4d515f" fontSize="16px" inline="true">
                  .
                </Text>
              </Box>
              <Box my={3}>
                <Box display="flex" alignItems="center" bgcolor='#EEF3F6' p={1} gap={1}>
                  <ErrorIcon sx={{ color: '#C2241D' }} fontSize="small" />
                  <Text color="#010B0E" fontSize="14px" fontWeight="600">
                    Service Account Information
                  </Text>
                </Box>
                <Box p={2} textAlign="justify" bgcolor="#F7F9FB">
                  <Typography component="span" fontSize={12} color="#5D6365">
                    Virginia American Water (VAW) provides AlexRenew with all account information and meter readings. For this reason, all AlexRenew account holders must also have an account with VAW.
                    To start or stop service, or to make changes to your name or mailing address on accounts with active service, you must contact VAW at
                    <Typography component="span" fontSize={12} color="#007395">&nbsp;(800) 452-6863</Typography>,
                    Monday through Friday from 7:00 a.m. to 7:00 p.m.
                  </Typography>
                </Box>
              </Box>

              {account?.myAccount?.serviceAddresses?.map(
                (item: any, index: number) => (
                  <Box
                    key={index}
                    border='1px solid #D7E3E9'
                    onClick={() => { setActive(0) }}
                  >
                    <Box
                      p={1}
                      display="flex"
                      alignItems="center"
                      bgcolor={active === 0 ? '#F7F9FB' : '#ffffff'}
                    >
                      <Radio
                        onChange={async () => { await handleCheckBox(item) }}
                        checked={item?.accountId === defaultAccountId && true}
                      />
                      <Box>
                        <Typography fontSize={10} color="#5D6365">
                          ACCOUNT NUMBER
                        </Typography>
                        <Typography fontSize={16} color="#010B0E">
                          {item?.accountId}
                        </Typography>
                      </Box>
                    </Box>
                    {isMobile
                      ? <Box p={1}>
                        <Box display="flex" flexDirection="column" mb={2}>
                          <Typography flex={1} color="#5D6365" fontSize={10}>
                            PROPERTY ADDRESS
                          </Typography>
                          <Typography flex={1} color="#343C3E" fontSize={14}>
                            {item?.serviceAddress}
                          </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" >
                          <Typography flex={1} color="#5D6365" fontSize={10}>
                            OLD ACCOUNT NUMBER
                          </Typography>
                          <Typography flex={1} color="#343C3E" fontSize={14}>
                            {item?.legacyAcctNumber}
                          </Typography>
                        </Box>
                      </Box>
                      : <Box m={1}>
                        <Box display="flex" p={1} borderBottom='1px solid #E7EEF2'>
                          <Typography flex={1} color="#5D6365" fontSize={10}>
                            PROPERTY ADDRESS (1)
                          </Typography>
                          <Typography flex={1} color="#5D6365" fontSize={10}>
                            OLD ACCOUNT NUMBER
                          </Typography>
                        </Box>
                        <Box display="flex" p={1}>
                          <Typography flex={1} color="#343C3E" fontSize={16}>
                            {item?.serviceAddress}
                          </Typography>
                          <Typography flex={1} color="#343C3E" fontSize={16}>
                            {item?.legacyAcctNumber}
                          </Typography>
                        </Box>
                      </Box>}
                  </Box>
                )
              )}
            </BoxWrapper>
          </Wrapper>
        </Grid>
        <Grid item sm={12} md={4}>
          {account !== null
            ? <BoxWrapper>
              <Box display="flex" alignItems="center" mb={3}>
                <PaymentsIcon fontSize='medium' sx={{ color: '#67D2DF' }} />
                <Typography ml={1} color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight="600">
                  Current Bill Detail
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={3}
              >
                <Text color="#000000" fontSize="18px">
                  Total Due
                </Text>
                <Box
                  p="12px 16px"
                  sx={{ background: '#EEF3F6', borderRadius: '4px' }}
                >
                  <Text color="#010B0E" fontSize="24px" fontWeight="600">
                    {userValue?.balance}
                  </Text>
                </Box>
              </Box>
              <InvoiceCloudFrame page={1}>
                <MButton
                  fullWidth
                  rounded="true"
                  variant="contained"
                  texttransform='none'
                  size="large"
                >
                  Pay Bill
                </MButton>
              </InvoiceCloudFrame>
              <Typography color="#5D6365" fontSize={16} my={2} textAlign={isMobile ? 'center' : 'left'}>
                To download your invoice,&nbsp;
                <Link
                  href={account?.myAccount?.billDisplayURL}
                  target="_blank"
                  rel="noreferrer"
                  color='#007395'
                  style={{ textDecoration: 'none' }}
                >
                  Click Here.
                </Link>
              </Typography>
              <Typography color="#5D6365" fontSize={14}>
                Did you know you can now pay your bill using Apple Pay, Google
                Pay, Venmo, or Paypal?
              </Typography>

              <Typography display="flex" color="#5D6365" fontSize={14} my={2} textAlign={isMobile ? 'center' : 'left'}>
                Need a Payment Plan?
                <Link
                  ml={1}
                  href={PAYMENT_PLAN_URL}
                  target="_blank"
                  rel="noreferrer"
                  color='#007395'
                  style={{ textDecoration: 'none' }}
                >
                  Click Here.
                </Link>
              </Typography>
            </BoxWrapper>
            : <ProgressLoaders height="100%" />}
        </Grid>
      </Grid>
    </>

  )
}

const GeneralInformation = ({ userValue }: { userValue: IUserValue }): JSX.Element => {
  return (
    <Grid borderRadius="4px" container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color="#4d515f" fontSize="12px" margin="0 0 2px">
            NAME
          </Text>
          <Text color="#000" fontSize="16px">
            {userValue?.name}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color="#4d515f" fontSize="12px" margin="0 0 2px">
            HOME / OFFICE NUMBER
          </Text>
          <Text color="#000" fontSize="16px">
            {userValue && (userValue.mobileNumber ?? userValue.officeNumber)}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color="#4d515f" fontSize="12px" margin="0 0 2px">
            MAILING ADDRESS
          </Text>
          <Text color="#000" fontSize="16px">
            {userValue?.mailingAddress}
          </Text>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box mb={2.5}>
          <Text color="#4d515f" fontSize="12px" margin="0 0 2px">
            EMAIL ID
          </Text>
          <Text color="#000" fontSize="16px">
            {userValue?.email}
          </Text>
        </Box>
      </Grid>
    </Grid>
  )
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`

export const BoxWrapper = styled(Box)({
  background: '#FFFFFF',
  borderRadius: '4px',
  padding: '24px'
})
