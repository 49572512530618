import { Box, Dialog, IconButton, Typography, styled } from '@mui/material'
import BillExplainerImg from 'assets/images/billExplainer.jpg'
import { MButton, ProgressLoaders, SvgImage, Text } from 'components'
import { USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { NoData } from './NoData'
import { BillItem, BillingHeader } from './PaymentHistory'

export const BillingHistory = (): JSX.Element => {
  const { billing } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMobile = useMobileBreakpoints()
  const { account, currentAccount, getBilling, isAdmin, userEmail } =
    useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const handleModal = (): void => {
    setIsModalOpen(!isModalOpen)
  }

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === billing?.accountId) {
      return
    }
    void getBilling({
      AccessToken: authToken,
      accountId: currentAccount?.accountId ?? account?.myAccount.accountId,
      startDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      endDate: dayjs().format('YYYY-MM-DD'),
      admin: isAdmin,
      email: userEmail
    })
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, userEmail, isAdmin, authToken])


  const handleBillClick = (_event: any, billUrl: string): void => {
    window.open(billUrl, '_blank', 'noopener,noreferrer')
  }

  return (
    <Wrapper>
      {(billing?.billing !== null) && billing?.billing.length === 0 && (
        <NoData transactionType="billing" />
      )}
      {billing !== null
        ? billing?.billing.length !== 0 && <>
          {isMobile
            ? billing?.billing.map((bill, index) => {
              return <BillingEntryMobile key={index} date={bill.billDate} amount={bill.billAmount} url={bill.billDisplayURL} />
            })
            : <Box>
              <BillingHeader>
                <ItemWrapper>Bill Date</ItemWrapper>
                <ItemWrapper>Bill Amount</ItemWrapper>
                <ItemWrapper>
                  Bill Print File
                  <MButton texttransform='none' onClick={handleModal}>
                    View Bill Explainer
                  </MButton>
                </ItemWrapper>
              </BillingHeader>
              {billing?.billing.map((bill, index) => {
                return (
                  <BillItem key={index}>
                    <ItemWrapper small="true">{bill.billDate}</ItemWrapper>
                    <ItemWrapper small="true">{formatValue(bill.billAmount)}</ItemWrapper>
                    <ItemWrapper>
                      <Box display="flex" alignItems="center">
                        <MButton texttransform='none' onClick={(e) => { handleBillClick(e, bill.billDisplayURL) }}>
                          <Text fontSize="14px" color="#000" margin="0 10px 0 0">
                            Bill
                          </Text>
                          <SvgImage name="BillPdfIcon" width={24} height={24} />
                        </MButton>
                      </Box>
                    </ItemWrapper>
                  </BillItem>
                )
              }
              )}
            </Box>}
          <Dialog open={isModalOpen} onClose={handleModal} aria-labelledby="modal-title" maxWidth="lg" fullScreen={!!isMobile}>
            <Box>
              <Box p={3} display="flex" justifyContent='flex-end'>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={handleModal}
                  aria-label="close"
                >
                  <SvgImage name="CloseIcon" width={25} height={25} />
                </IconButton>
              </Box>
              <img
                src={BillExplainerImg}
                alt="Alex Renew Footer"
                width="100%"
                height="100%"
              />
            </Box>
          </Dialog>
        </>
        : <ProgressLoaders height="100%" />
      }
    </Wrapper >
  )
}

export const Wrapper = styled(Box)({
  background: '#fff',
  padding: '24px 20px',
  marginBottom: '10px'
})

export const ItemWrapper = styled(Box)<{ small?: string }>(({ small }) => ({
  width: '25%',
  color: '#000',
  fontSize: small === 'true' ? '14px' : '16px',
  fontWeight: small === 'true' ? '400' : 'bold',
  display: 'flex',
  alignItems: 'center',
  gap: 5
}))

interface BillingEntryMobileProps {
  date: string
  amount: string
  url: string
}

const BillingEntryMobile: React.FC<BillingEntryMobileProps> = (props: BillingEntryMobileProps) => {
  return <Box py={2} borderBottom="solid 1px #E7EEF2">
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color="#5D6365">Bill Date</Typography>
      <Typography fontSize={14} color="#010B0E" fontWeight={700}>{dayjs(props.date).format('MM-DD-YYYY')}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color="#5D6365">Payment Amount</Typography>
      <Typography fontSize={14} color="#010B0E" fontWeight={700}>{formatValue(props.amount)}</Typography>
    </Box>
    <Box display="flex" justifyContent="space-between">
      <Typography fontSize={14} color="#5D6365">Bill Print Fle</Typography>
      <Typography fontSize={14} color="#010B0E" fontWeight={700}><MButton padding="0px" texttransform='none'><u>Download PDF</u></MButton></Typography>
    </Box>
  </Box>
}
