export const ROUTES_PATH = {
  // Auth
  SIGNIN: '/signin',
  SIGN_IN_AS_CUSTOMER: '/admin/sign-in/customer',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_RESET: '/password-reset',
  SIGNUP_SUCCESS: '/signup/success',
  SIGNUP_CONFIRM: '/signup/confirm',
  NEWPASSWORD: '/newPassword',
  CUSTOMER: '/customer/:email',
  ACCOUNT: '/account',
  BILLING: '/billing',
  CONTACT: '/contact',
  CONTACT_US: '/contact-us',
  EFFICIENCY: '/efficiency',
  FAQS: '/faq',
  AUTHORIZEDFAQS: '/faqs',
  PAY: '/pay',
  SERVICES: '/service',
  AUTHORIZEDSERVICES: '/services',
  SERVICE_RETURN: '/service-return',
  SETTINGS: '/settings',
  USAGE: '/usage',
  PORTAL_DASHBOARD: '/portal/dashboard',
  SAVINGS: '/saving',
  AUTHORIZEDSAVINGS: '/savings',
  DONATIONS: '/donations',

  // main
  DASHBOARD: '/'
}
