import { Box, CircularProgress } from '@mui/material'

interface IProgressLoaders {
  height?: string
}

export const ProgressLoaders = ({ height }: IProgressLoaders): JSX.Element => {
  const centerDiv = {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: height ?? '100vh'
  }

  return (
    <Box sx={centerDiv}>
      <CircularProgress color="primary" disableShrink />
    </Box>
  )
}
