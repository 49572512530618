import { Box, Typography, styled } from '@mui/material'
import HighestYear from 'assets/images/highest-year.png'
import UsageBg from 'assets/images/usage-bg.png'
import UsageMonth from 'assets/images/usage-month.png'
import { type IDashboardStats } from 'containers/Dashboard/Dashboard'
import { formatValue } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IProps {
  dashboardStats: IDashboardStats
}
export const NumberStats = ({ dashboardStats }: IProps): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Background p={3}>
        <Box display="flex" alignItems="center" gap={2}>
          <img src={UsageMonth} height={isMobile ? '57px' : '90px'} alt="usage logo" />
          <Box>
            <Box display="flex" color="#ffffff" gap={1} alignItems="flex-end">
              <Typography fontSize={isMobile ? '32px' : '52px'}>{formatValue(`${dashboardStats.usageThisMonth}`, false, false)}</Typography>
              <Typography fontSize={isMobile ? 16 : 18} mb={isMobile ? 1 : 2}>TGAL</Typography>
            </Box>
            <Typography fontSize={isMobile ? 12 : 16} color="#67D2DF" letterSpacing={1}>USAGE THIS BILL</Typography>
          </Box>
        </Box>
      </Background>
      <Background p={3}>
        <Box display="flex" alignItems="center" gap={2}>
          <img src={HighestYear} height={isMobile ? 70 : 90} alt="usage logo" />
          <Box>
            <Box display="flex" color="#ffffff" gap={1} alignItems="flex-end">
              <Typography fontSize={isMobile ? '32px' : '52px'}>{formatValue(`${dashboardStats.highestThisYear}`, false, false)}</Typography>
              <Typography fontSize={18} mb={isMobile ? 1 : 2}>TGAL</Typography>
            </Box>
            <Typography fontSize={isMobile ? 12 : 16} color="#67D2DF" letterSpacing={1}>HIGHEST THIS YEAR</Typography>
          </Box>
        </Box>
      </Background>
    </Box>
  )
}

const Background = styled(Box)({
  backgroundImage: `url(${UsageBg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: '10px'
})
