import { Box, Divider, Link, Typography, styled } from '@mui/material'
import Saving1Img from 'assets/images/saving_1.png'
import Saving2Img from 'assets/images/saving_2.png'
import SavingCard1Img from 'assets/images/saving_card_1.png'
import SavingCard2Img from 'assets/images/saving_card_2.png'
import { Text } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

const DATA = [
  {
    id: 1,
    title: 'Winter Quarter Average',
    img: Saving1Img,
    subTitle: 'Residential',
    desc: [
      'For customers that qualify, the winter quarter average serves as a cap based on a customer’s water use history from December through February (Winter Quarter). The cap is used to ensure customers are billed for indoor water use only — meaning water that is used at a home and makes its way to AlexRenew’s wastewater treatment plant. Qualified customers will be billed from March through November using their actual water use or Winter Quarter Average, whichever is lower.'
    ],
    subDesc:
      '',
    cardTitle: 'Winter Averaging Explained',
    cardDesc: 'For more information, please click here.',
    cardImg: SavingCard1Img,
    cardLink: 'https://alexrenew.com/winteraverage'
  },
  {
    id: 2,
    title: 'Deduct Meters',
    img: Saving2Img,
    subTitle: 'Commercials',
    desc: [
      'Commercial and Industrial customers who have a portion of their wastewater flow that does not enter the wastewater collection system — for things like irrigation, cooling towers, swimming pools (that do not drain to the sewer), etc. — may apply to join the Deduct Meter Program.',
      'Customers participating in the program shall have the amount of flow that goes through an approved, properly functioning, deduct meter removed from the Wastewater Treatment Charge usage portion of their bill. This deduction, for some customers, can result in significant savings on their bill.'
    ],
    subDesc:
      'Please note that it is solely the responsibility of the customer to maintain, in good working order, all the installed equipment required for AlexRenew to get accurate deduct meter readings.',
    cardTitle: 'Program Requirements',
    cardDesc:
      'Download the program requirements and complete an application for the deduct meter program.',
    cardImg: SavingCard2Img,
    cardLink:
      'https://alexrenew.com/sites/default/files/2022-04/Deduct%20Meter%20Application%20%28002%29_0.pdf'
  }
]

interface ISavings {
  isAuthenticated?: boolean
}


export const Savings: React.FC<ISavings> = ({ isAuthenticated = true }: ISavings): JSX.Element => {
  const isMobile = useMobileBreakpoints()

  return (
    <>
      {(isMobile && (isAuthenticated)) && <QuickLinks />}
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        bgcolor="#FFFFFF"
        p={isMobile ? 0 : 2}
        mt={3}
      >
        {DATA.map((item) => (
          <Box key={item.id} flex={1} borderRadius={3} p={isMobile ? 0 : 1}>
            <Box display="flex" flexDirection="column" height="100%">
              {isMobile && <Box display="flex" flexDirection="column" my={2} p={2} borderBottom="solid 2px #D7E3E9">
                <Typography
                  color="#010B0E"
                  fontSize={18}
                  fontWeight={600}
                  mr={1}
                >
                  {item.title}
                </Typography>
                <Typography color="#01545F" fontSize={12}>
                  {item.subTitle}
                </Typography>
              </Box>}
              {isMobile
                ? <Box mx={2}>
                  <img src={item.img} width="100%" alt={item.title} />
                </Box>
                : <img src={item.img} width="100%" alt={item.title} />}
              <Box
                py={3}
                px={isMobile ? 0 : 2}
                border={isMobile ? 0 : '1px solid #D7E3E9'}
                height="100%"
                mx={isMobile ? 2 : 0}
              >
                {!isMobile &&
                  <Box display="flex" alignItems="center" mb={2}>
                    <Typography
                      color="#010B0E"
                      fontSize={24}
                      fontWeight={600}
                      mr={1}
                    >
                      {item.title}
                    </Typography>
                    <Box p="6px 12px" bgcolor="#EBF9FB" borderRadius={2}>
                      <Typography color="#01545F" fontSize={12}>
                        {item.subTitle}
                      </Typography>
                    </Box>
                  </Box>
                }
                <Box>
                  {item.desc.map((li, index) => (
                    <Text
                      key={`${item.title}-${index}`}
                      color="#5D6365"
                      fontSize={isMobile ? '12px' : '16px'}
                      margin={item.desc.length > index + 1 ? '0 0 20px' : '0'}
                    >
                      {li}
                    </Text>
                  ))}
                </Box>
                <Box my={2}>
                  <Divider sx={{ bgcolor: '#D7E3E9' }} />
                </Box>
                <SubDesc color="#5D6365" fontSize="12px">
                  {item.subDesc}
                </SubDesc>
                <Link
                  href={item.cardLink}
                  sx={{ textDecoration: 'none' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    mt={3}
                    p={2}
                    display="flex"
                    alignItems="flex-start"
                    bgcolor="#F7F9FB"
                    borderRadius="4px"
                  >
                    <img src={item.cardImg} alt={item.cardTitle} />
                    <Box
                      ml={1.5}
                      display="flex"
                      flexDirection="column"
                      justifyItems="center"
                    >
                      <Typography
                        color="#010B0E"
                        fontSize={isMobile ? 14 : 18}
                        fontWeight={600}
                      >
                        {item.cardTitle}
                      </Typography>
                      <Typography color="#5D6365" fontSize={isMobile ? 14 : 18}>
                        {item.cardDesc}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </>

  )
}

const SubDesc = styled(Text)`
  font-style: italic;
`
