import { Alert, Typography } from '@mui/material'

interface SecurityAlertProps {
    onClose: () => void
}

export const SecurityAlert: React.FC<SecurityAlertProps> = ({
    onClose
}: SecurityAlertProps): JSX.Element => {
    return (
        <Alert
            severity="warning"
            icon={false}
            onClose={onClose}
            sx={{
                bgcolor: '#F9AE23',
                color: '#FFFFFF',
                px: { xs: 2, md: 30 },
                py: 3,
                textAlign: 'center'
            }}
        >
            <Typography fontWeight="bold" fontSize="24px">
                AlexRenew Payment Portal & Pay by Phone Maintenance
            </Typography>
            <Typography fontWeight="bold">
                On Sunday, November 3rd from 5:00am to 8:00am, AlexRenew’s pay by phone system and payment portal will be down for routine maintenance.  The maintenance window has been scheduled for a three hour window, but may require less time to complete.  Sorry for any inconvenience while this necessary update is performed.  If you have further questions, please contact customer service at (703) 721-3500 or billing@alexrenew.com.
            </Typography>
        </Alert>
    )
}
