import { useLayoutEffect, useState } from 'react'

interface WindowSize {
  width: number
  height: number
}

export const useWindowSize = (): WindowSize => {
  const [size, setSize] = useState<WindowSize>({ width: window.innerWidth, height: window.innerHeight })

  useLayoutEffect(() => {
    function updateSize (): void {
      setSize({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', updateSize)
    updateSize()

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [])

  return size
}
