import {
  Chat,
  Email,
  Phone
} from '@mui/icons-material'
import { Box, Link } from '@mui/material'
import TwitterIcon from 'assets/icons/Social/TW.svg'
import { Text } from 'components'
import { CONTACT_INFORMATION, CONTACT_SOCIAL_ITEMS } from 'config'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IProps {
  isAuthenticated?: boolean

}

const contactIcons = {
  phone: Phone,
  chat: Chat,
  email: Email
}


export const FindUs = ({ isAuthenticated = true }: IProps): JSX.Element => {
  const isMobile = useMobileBreakpoints()
  return (
    <Box flex={1}>
      <Box mb={3}>
        <Text color="#010B0E" fontWeight="600" fontSize="18px" margin="0 0 8px">
          Starting and Stopping Service:
        </Text>
        <Text color="#5D6365" fontSize="16px" inline="true">
          To start or stop service, you must contact Virginia American Water at
          &nbsp;
          <Text color="#007395" fontSize="16px" inline="true">
            (800) 452-6863
          </Text>
          . Monday through Friday from 7:00 a.m. to 7:00 p.m.
        </Text>
      </Box>
      <Box mb={3}>
        <Text color="#010B0E" fontWeight="600" fontSize="18px" margin="0 0 8px">
          Update Contact Information:
        </Text>
        <Text color="#5D6365" fontSize="16px">
          To make changes to your name or mailing address on accounts with
          active service, you must contact Virginia American Water at &nbsp;
          <Text color="#007395" fontSize="16px" inline="true">
            (800) 452-6863
          </Text>
          . Monday through Friday from 7:00 a.m. to 7:00 p.m. All other changes
          can be made through the profile of your online AlexRenew Account or
          submitted through the message box above.
        </Text>
      </Box>
      <Box mb={3}>
        <Text color="#010B0E" fontWeight="600" fontSize="18px" margin="0 0 8px">
          Sewer Line Back Up / Maintenance:
        </Text>
        <Text color="#5D6365" fontSize="16px">
          Please contact the City of Alexandria at &nbsp;
          <Text color="#007395" fontSize="16px" inline="true">
            (703) 746-4357
          </Text>
          .
        </Text>
      </Box>
      <Box mb={3}>
        <Text color="#010B0E" fontWeight="600" fontSize="18px" margin="0 0 8px">
          Limerick Field Issues:
        </Text>
        <Text color="#5D6365" fontSize="16px">
          The City of Alexandria manages the maintenance, repairs and
          programming of Limerick Field. Please contact the City of Alexandria
          Department of Recreation, Parks and Cultural Resources at &nbsp;
          <Text color="#007395" fontSize="16px" inline="true">
            (703) 746-4343
          </Text>{' '}
          if you have any issues related to the City&apos;s field.
        </Text>
      </Box>
      <Box mb={3}>
        <Text color="#010B0E" fontWeight="600" fontSize="18px" margin="0 0 8px">
          Stormwater Questions:
        </Text>
        <Text color="#5D6365" fontSize="16px">
          Please contact the City of Alexandria Stormwater Management at &nbsp;
          <Text color="#007395" fontSize="16px" inline="true">
            (703) 746-4357
          </Text>
          .
        </Text>
      </Box>
      <Box mb={3}>
        <Text color="#010B0E" fontWeight="600" fontSize="18px" margin="0 0 8px">
          Trash Collection/Recycling Questions:
        </Text>
        <Text color="#5D6365" fontSize="16px">
          Alexandria Renew Enterprises does not provide trash collection or
          recycling services. Please contact your service provider with
          questions or contact the City of Alexandria at &nbsp;
          <Text color="#007395" fontSize="16px" inline="true">
            (703) 746-4128
          </Text>
          .
        </Text>
      </Box>
      {!isAuthenticated && (
        <Box mb={3}>
          <Text
            color="#010B0E"
            fontWeight="600"
            fontSize="18px"
            margin="0 0 8px"
          >
            Alexandria Renew Enterpises Billing or Customer Service Questions:
          </Text>
          <Text color="#5D6365" fontSize="16px">
            Please contact us at{' '}
            <Text color="#007395" fontSize="16px" inline="true">
              {' '}
              (703) 721-3500{' '}
            </Text>{' '}
            and press option 1. Monday-Friday 6:00 a.m. to 6:00 p.m. (except
            holidays) &nbsp;
          </Text>
        </Box>
      )}
      <>
        <Box pt="24px" m="24px 0 24px" sx={{ borderTop: '1px solid #D7E3E9' }}>
          <Text
            color="#000000"
            fontWeight="700"
            fontSize="18px"
          >
            Contact Information
          </Text>
          <Box
            my={2}
            display="flex"
            flexDirection={(isMobile || isAuthenticated) ? 'column' : 'row'}
            gap={2}
          >
            {CONTACT_INFORMATION.map((contact, index) => {
              const ContactIcon =
                contactIcons[contact.icon as keyof typeof contactIcons]
              return (
                <Box
                  display="flex"
                  key={index}
                  flexWrap="wrap"
                  sx={{
                    alignItems: 'center',
                    backgroundColor: '#F7F9FB',
                    padding: '15px 15px 25px 15px',
                    '.contact-link': {
                      color: '#007395',
                      textDecoration: 'underline'
                    }
                  }}
                >
                  <ContactIcon
                    sx={{
                      color: '#0092BD',
                      marginRight: '10px',
                      backgroundColor: '#E7EEF2',
                      padding: '10px',
                      borderRadius: '100px',
                      marginTop: '10px'
                    }}
                  />
                  <Text color="#010B0E" fontSize="18px" fontWeight="600">
                    {contact.title}
                  </Text>
                  <span
                    style={{
                      width: '100%',
                      marginLeft: '55px',
                      marginTop: '-5px'
                    }}
                  >
                    <Text color="#5D6365" fontSize="16px">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: contact.description
                        }}
                      />
                    </Text>
                  </span>
                </Box>
              )
            })}
          </Box>
        </Box>
        {isAuthenticated && (
          <Box display="flex" pt={3} gap={2} flexDirection={isMobile ? 'column' : 'row'}>
            <Text color="#000000" fontWeight="700" fontSize="18px">
              Find us at:
            </Text>
            <Box>
              {CONTACT_SOCIAL_ITEMS.map((social, index) => {
                return (
                  <Link
                    key={index}
                    display='flex'
                    mb={2}
                    alignItems='center'
                    sx={{
                      textDecoration: 'none'
                    }}
                    onClick={() => {
                      window.open(social.link)
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {social.icon === null
                      ? <img
                        src={TwitterIcon}
                        alt="Twitter"
                        style={{
                          width: '18px',
                          height: '18px',
                          marginLeft: '3px'
                        }}
                      />
                      : <social.icon sx={{ color: '#0092BD' }} />}
                    <Text
                      fontSize="14px"
                      block="false"
                      color="#000"
                      margin="0 0 0 10px"
                    >
                      {social.title}
                    </Text>
                  </Link>
                )
              })}
            </Box>
          </Box>
        )}
      </>
    </Box>
  )
}
