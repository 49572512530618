import { Box } from '@mui/material'
import { Text } from 'components'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { defaultOptions, screenType, useWindowSize } from 'utils'
import { type UsageData } from 'utils/hooks/types'

interface IChartData {
  date: string[]
  value: number[]
}

interface IProps {
  dashboardUsage: UsageData | null
}

export const PowerUsage = ({ dashboardUsage }: IProps): JSX.Element => {
  const { width } = useWindowSize()
  const [usageData, setUsageData] = useState<IChartData>({
    date: [],
    value: []
  })

  useEffect(() => {
    if (((dashboardUsage?.usageHistory) != null) && dashboardUsage?.usageHistory.length > 0 && (dashboardUsage?.usageHistory[0].usageData) != null) {
      const usageData = dashboardUsage.usageHistory[0].usageData
      const date: string[] = usageData.map((item: any) =>
        dayjs(item.period).format('MMM')
      )
      const value: number[] = usageData.map((item: any) =>
        parseFloat(item.value)
      )
      setUsageData({ date, value })
    } else {
      setUsageData({ date: [], value: [] })
    }
  }, [dashboardUsage])

  const options: any = {
    ...defaultOptions,
    chart: {
      fontFamily: 'Lato, sans-serif',
      animations: {
        enabled: false
      },
      foreColor: '#5D6365'
    },
    series: [
      {
        name: 'Usage TGAL',
        data: usageData.value
      }
    ],
    xaxis: {
      categories: usageData.date
    },
    colors: '#67D2DF'
  }

  return (
    <Box
      height="100%"
      sx={{
        background: '#FFFFFF',
        borderRadius: '4px'
      }}
    >
      <Box padding={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Box>
            <Text
              color="#000"
              fontWeight="700"
              fontSize={screenType(width, 'sm') ? '15px' : '18px'}
            >
              Usage History
            </Text>
            <Text color="#5D6365" fontSize="12px">
              x1000 Gallons (TGAL)
            </Text>
          </Box>
        </Box>
        <Chart
          options={options}
          series={options.series}
          type="bar"
          height="100%"
        />
      </Box>
    </Box>
  )
}
