import { Typography } from '@mui/material'
import { css, styled } from '@mui/material/styles'
import { type Placement } from '@popperjs/core'
import { type ReactNode } from 'react'

export interface ITextProps {
  /**
   * The text to render
   */
  children?: ReactNode

  /**
   * One of the following heading tag and styling will be used
   */
  heading?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

  /**
   * Text color key from theme
   * @default 'text'
   */
  color?: string

  /**
   * Text font weight, key from theme
   * @default 'semibold' for non-headings
   * @default 'bold' for headings
   */
  fontWeight?: string

  /**
   * Text font size, key from theme
   * @default 'text'
   */
  fontSize?: string

  /**
   * Text font size, in exact px/rem
   */
  fontSizeExact?: number | string

  /**
   * Text aligntment
   * @default 'left'
   */
  align?: 'left' | 'center' | 'right'

  /**
   * Display as block
   */
  block?: string

  /**
   * Display inline
   */
  inline?: string

  /**
   * Use uppercase transform
   */
  uppercase?: string

  ishover?: string

  nowrap?: string

  /**
   * The number of visible rows
   * @default 0
   */
  visiblerows?: number

  /**
   * Specify ellipsis tooltip placement
   */
  tooltipPlacement?: Placement

  /**
   * Needed for overriding styles with styled(Text)
   */
  className?: string

  padding?: string | number

  margin?: string | number
}

export function Text(props: ITextProps): JSX.Element {
  const {
    heading = props.heading ?? 'span',
    align = 'left',
    fontSize = 'text',
    fontWeight = '400',
    color = props.heading ? 'Black' : 'Gray',
    children,
    visiblerows = 0,
    tooltipPlacement,
    block = 'true',
    ishover = 'false',
    nowrap = 'false',
    ...rest
  } = props

  return (
    <Wrapper
      {...rest}
      as={heading}
      align={align}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      block={block}
      ishover={ishover}
      visiblerows={visiblerows}
      nowrap={nowrap}
    >
      {children}
    </Wrapper>
  )
}

type TWrapperProps = ITextProps &
  Required<Pick<ITextProps, 'fontSize' | 'fontWeight' | 'color'>>

const Wrapper = styled(Typography) <TWrapperProps>`
  display: inline-block;

  ${({ theme, ...props }) => css`
    font-weight: ${props.fontWeight};
    color: ${props.color};
    text-align: ${props.align};
    font-size: ${props.fontSize};

    ${props.block === 'true' &&
    css`
      display: block;
    `}

    ${props.inline === 'true' &&
    css`
      display: inline;
    `}

    ${props.padding &&
    css`
      padding: ${props.padding};
    `}

    ${props.margin &&
    css`
      margin: ${props.margin};
    `}

    ${props.uppercase === 'true' &&
    css`
      text-transform: uppercase;
    `}

    ${props.nowrap === 'true' &&
    css`
      white-space: nowrap;
    `}

    ${props.ishover === 'true' &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}

    ${props.visiblerows &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${props.visiblerows};
      -webkit-box-orient: vertical;
    `}
  `}
`
