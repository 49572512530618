import GlobalStyles from '@mui/material/GlobalStyles'
import ReactGA from 'react-ga4'
import { ThemeProvider } from '@mui/system'
import { Amplify } from 'aws-amplify'
import Chat from 'components/domain/8x8Chat/Chat'
import { awsConfig, globalStyles, theme } from 'config'
import { AuthProvider, SidebarProvider } from 'context'
import { SnackbarProvider } from 'notistack'
import { ROUTES } from 'routes'

ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS}`)

Amplify.configure(awsConfig)

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SidebarProvider>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
          >
            <GlobalStyles styles={globalStyles} />
            <ROUTES />
            <Chat />
          </SnackbarProvider>
        </SidebarProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
