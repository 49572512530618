import { Box, styled } from '@mui/material'
import AlexRenewFooterImg from 'assets/images/AlexRenew_Footer.png'
import { ProgressLoaders } from 'components'
import { useAuth } from 'context'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { SideBar, TopBar } from './component'

export const AuthorizedLayout: React.FC = () => {
  const location = useLocation()
  const { isLoading } = useAuth()
  const isMobile = useMobileBreakpoints()

  useEffect(() => {
    const mainContentElement = document.getElementById('main-content')
    if (mainContentElement != null) {
      mainContentElement.scrollTop = 0
    }
  }, [location])

  if (isLoading) {
    return (
      <Box>
        <ProgressLoaders height="100vh" />
      </Box>
    )
  }

  return (
    <Main>
      <SideBar />
      <TopBar />
      <MainContent id="main-content" mt={isMobile ? 0 : '200px'}>
        <Box padding={3} mt={isMobile ? 0 : 2}>
          <Outlet />
          <Box mt={3} p={3} borderRadius="4px" bgcolor="#FFFFFF">
            <img src={AlexRenewFooterImg} alt="Alex Renew Footer" width="100%" />
          </Box>
        </Box>
      </MainContent>
    </Main>
  )
}

const Main = styled('main')({
  background: '#f3f6ff',
  overflow: 'hidden',
  position: 'relative',
  minHeight: '100vh'
})

const MainContent = styled(Box)`
  background: #f7f9fb;
  overflow: auto;

  @media screen and (min-width: 1200px) {
    margin-left: auto;
    width: calc(100% - 240px);
    height: calc(100vh - 200px);
  }
`
