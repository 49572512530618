export function getInitials (personName: string): string {
  const nameParts = personName.split(',')
  if (nameParts.length !== 2) {
    return ''
  }

  const firstName = nameParts[1].trim()
  const lastName = nameParts[0].trim()

  const firstInitial = firstName.charAt(0).toUpperCase()
  const lastInitial = lastName.charAt(0).toUpperCase()

  return `${firstInitial}${lastInitial}`
}
