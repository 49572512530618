import { Grid } from '@mui/material'
import { AuthLayout } from 'components'
import { WELCOME_DESCRIPTION, WELCOME_TITLE } from 'config'
import { AuthMain, AuthWrapper } from '../SignIn'
import { Form } from './component'

export const SignupConfirm: React.FC = () => {
  return (
    <AuthLayout
      isAuthPage={true}
      title={WELCOME_TITLE}
      desc={WELCOME_DESCRIPTION}
    >
      <AuthWrapper isAuthPage={true}>
        <Grid container justifyContent='center' mb='10vh'>
          <Grid item xs={12} md={6}>
            <AuthMain isRounded={true}>
              <Form />
            </AuthMain>
          </Grid>
        </Grid>
      </AuthWrapper>
    </AuthLayout>
  )
}
