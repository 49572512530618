import { Box, css, styled } from '@mui/material'
import { Text } from 'components'
import { EFFICIENCY_DATA } from 'config'
import { Wrapper } from 'containers/Billing/components'

export const Efficiency: React.FC = () => {
  return (
    <Wrapper>
      <Box padding="18px 20px">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb="20px"
        >
          <Text color="#000" fontSize="18px">
            {EFFICIENCY_DATA.title}
          </Text>
          <Text color="#4d515f" fontSize="12px" align="right">
            {EFFICIENCY_DATA.subtitle}
          </Text>
        </Box>
        <Box>
          {EFFICIENCY_DATA.type === 'empty'
            ? (
            <Box />
              )
            : EFFICIENCY_DATA.type === 'items'
              ? (
            <Box>
              {EFFICIENCY_DATA.items.map((item: any) => (
                <OutagesItem
                  key={item.title}
                  hasbackground={
                    EFFICIENCY_DATA.itemBackground ? 'true' : 'false'
                  }
                >
                  <Text color="#000" fontSize="16px">
                    {item.title}
                  </Text>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    View
                  </a>
                </OutagesItem>
              ))}
            </Box>
                )
              : (
            <Box />
                )}
        </Box>
      </Box>
    </Wrapper>
  )
}

const OutagesItem = styled(Box)<{ hasbackground?: string }>`
  border-bottom: 1px solid #dddddd;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px 0;

  &:last-child {
    border: none;
  }

  a {
    color: #1513c3;
    text-decoration: none;
  }

  ${(props) => css`
    ${props.hasbackground === 'true' &&
    css`
      background: #f9f9f9;
      border: none;
      padding: 12px 15px;
      margin-bottom: 13px;
    `}
  `}
`
