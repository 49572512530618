import styled from '@emotion/styled'
import { Box, Grid } from '@mui/material'
import { AuthLayout, MButton, SvgImage, Text } from 'components'
import { ROUTES_PATH, WELCOME_DESCRIPTION, WELCOME_TITLE } from 'config'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { AuthMain, AuthWrapper } from '../SignIn'

export const SignupSuccess: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()


  const handleBack = (): void => {
    navigate(ROUTES_PATH.SIGNIN)
  }

  const IconComponent = styled(Box)`
    align-content: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
  `
  if (location.key === 'default') {
    return <Navigate to={ROUTES_PATH.SIGNIN} state={{ from: location }} replace />
  }

  return (
    <AuthLayout
      title={WELCOME_TITLE}
      desc={WELCOME_DESCRIPTION}
      isAuthPage={true}
    >
      <AuthWrapper isAuthPage={true}>
        <Grid container justifyContent='center'>
          <Grid item xs={12} md={6}>
            <AuthMain isRounded={true}>
              <IconComponent>
                <SvgImage name="GreenCheckIcon" width={250} height={62} />
                <Text
                  heading="h2"
                  color="#010B0E"
                  fontSize="24px"
                  align="center"
                  margin="16px 0 30px"
                  fontWeight="600"
                >
                  You have been registered
                </Text>
              </IconComponent>
              <Text fontSize="16px" color="#5D6365" margin="0 0 20px">
                You have successfully registered your account at MyAlexRenew.{' '}
              </Text>
              <Text fontSize="16px" color="#5D6365" margin="10 0 20px">
                If you did not receive the email, please call AlexRenew Customer
                Service at{' '}
                <a href="tel:(703) 721-3500" color="#007395">
                  (703) 721-3500
                </a>{' '}
                and press option 1.
              </Text>

              <Box mt={3} mb={1} display="flex" justifyContent="center">
                <MButton
                  type="button"
                  variant="contained"
                  size="large"
                  rounded="true"
                  sx={{ width: '200px', marginTop: '32px' }}
                  onClick={handleBack}
                >
                  Back to log in
                </MButton>
              </Box>
            </AuthMain>
          </Grid>
        </Grid>
      </AuthWrapper>
    </AuthLayout>
  )
}
