export const defaultOptions = {
  chart: {
    type: 'bar',
    fontFamily: 'Lato, sans-serif',
    animations: {
      enabled: false
    },
    width: '100vw'
  },
  colors: '#67D2DF',
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '25%'
    }
  },
  dataLabels: {
    enabled: false
  },
  noData: {
    text: 'No data available',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: 'grey',
      fontSize: '25px',
      fontFamily: 'sans-serif',
      fontWeight: '900',
      backgroundColor: 'white'
    }
  }
}
