export const screenType = (currentWidth: number, screenBreakpoint: string): boolean => {
  if (currentWidth < 600 && screenBreakpoint === 'sm') {
    return true
  }
  if (currentWidth >= 600 && screenBreakpoint === 'md') {
    return true
  }
  if (currentWidth >= 900 && screenBreakpoint === 'lg') {
    return true
  }
  if (currentWidth >= 1200 && screenBreakpoint === 'xl') {
    return true
  }
  if (currentWidth >= 1536 && screenBreakpoint === 'xxl') {
    return true
  }
  return false
}
