import { Button, CircularProgress, type ButtonProps } from '@mui/material'
import { css, styled } from '@mui/material/styles'
import { type ReactNode } from 'react'

interface ISButtonProps extends ButtonProps {
  padding?: string
  texttransform?: string
  rounded?: string
  bgcolor?: string
  loading?: boolean
  children?: ReactNode
}

export function MButton({
  padding = '',
  texttransform = 'false',
  rounded = 'false',
  bgcolor = '',
  loading = false,
  children,
  ...props
}: ISButtonProps): JSX.Element {
  return (
    <CustomButton
      padding={padding}
      texttransform={texttransform}
      rounded={rounded}
      bgcolor={bgcolor}
      {...props}
    >
      {loading
        ? (
          <CircularProgress sx={{ color: '#fff' }} size={27} />
        )
        : (
          children
        )}
    </CustomButton>
  )
}

const CustomButton = styled(Button) <ISButtonProps>`
  ${({ theme, ...props }) => css`
    ${props.texttransform &&
    css`
      text-transform: ${props.texttransform} !important;
    `}
    ${props.padding &&
    css`
      padding: ${props.padding} !important;
    `}
    ${props.rounded === 'true' &&
    css`
      border-radius: 20px;
    `}
    ${props.bgcolor &&
    css`
      background-color: ${props.bgcolor} !important;
    `}
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  `}
`
