import { useEffect, useState } from 'react'

export function useLocalStorage<T>(key: string, initialValue: T): readonly [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const item = window.localStorage.getItem(key)
    return (item != null) ? JSON.parse(item) : initialValue
  })

  const setValue = (value: T): void => {
    setStoredValue(value)
    window.localStorage.setItem(key, JSON.stringify(value))
  }

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent): void => {
      if (event.key === key) {
        setStoredValue((event.newValue != null) ? JSON.parse(event.newValue) : initialValue)
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key, initialValue])

  return [storedValue, setValue] as const
}
