import { Box, Typography } from '@mui/material'

import { MButton, ProgressLoaders, SvgImage } from 'components'
import { MESSAGES, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { formatValue, useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

export const UtilityBill: React.FC = () => {
  const { account, currentAccount, getAccount, isAdmin, userEmail } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const isMobile = useMobileBreakpoints()
  const [prevAccountId, setPrevAccountId] = useState<string>('')
  const [authToken] = useLocalStorage(USER_TOKEN, '')

  const handleDownloadBill = (): void => {
    if ((account?.myAccount?.billDisplayURL) !== null) {
      window.open(account?.myAccount?.billDisplayURL, '_blank', 'noopener,noreferrer')
    } else {
      enqueueSnackbar(MESSAGES.BILL_PDF_ERROR, {
        variant: 'error',
        autoHideDuration: 5000
      })
    }
  }

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  const handleViewRates = (): void => {
    window.open(
      'https://alexrenew.com/customer-center/rates-and-billing-residential-customers',
      '_blank'
    )
  }

  return (
    <Box bgcolor="#ffffff" pb={5}>
      {account !== null
        ? <>
          <Box display="flex" justifyContent="space-between" alignItems="center" p={3} borderBottom='solid 1px #D7E3E9'>
            <Box>
              <Typography color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight={600}>
                Account Billing Summary
              </Typography>
              <Typography display="inline" color="#5D6365" fontSize={12}>
                Account Number:
              </Typography>
              <Typography display="inline" fontSize={12} color='#010B0E' fontWeight={600} ml={0.5}>{account?.myAccount?.accountId}</Typography>
              <Box>
                <Typography display="inline" color="#5D6365" fontSize={12}>
                  Residential Service Bill Period:
                </Typography>
                <Typography display="inline" fontSize={12} color='#010B0E' fontWeight={600} ml={0.5}>{`${dayjs(account?.myAccount?.billStartDt).format('M/DD/YYYY')} to ${dayjs(account?.myAccount?.billEndDt).format('M/DD/YYYY')}`}</Typography>
              </Box>
            </Box>
            {!isMobile && <Box display="flex" alignItems="center" gap={2}>
              <MButton
                variant="outlined"
                texttransform="none"
                rounded="true"
                size="large"
                startIcon={<SvgImage name="ServiceThreeIcon" width={25} height={25} />}
                onClick={handleViewRates}
              >
                View Rates
              </MButton>
              <MButton
                variant="outlined"
                texttransform="none"
                rounded="true"
                size="large"
                startIcon={<SvgImage name="BillDownloadPdf" width={25} height={25} />}
                onClick={handleDownloadBill}
              >
                View PDF File
              </MButton>
            </Box>}
          </Box>
          <Box px={isMobile ? 2 : 3}>
            {((account?.myAccount?.wastewaterTreatmentCharge) != null) && <BillingEntry title='Wastewater Treatment Charge' amount={account?.myAccount?.wastewaterTreatmentCharge} />}
            {((account?.myAccount?.wastewaterBaseCharge) != null) && <BillingEntry title='Base Charge' amount={account?.myAccount?.wastewaterBaseCharge} />}
            {((account?.myAccount?.wastewaterCitySanitarySewerCharge) != null) && <BillingEntry title='City Sanitary Sewer System Capital Investment and Maintenance Fee' amount={account?.myAccount?.wastewaterCitySanitarySewerCharge} />}
            {((account?.myAccount?.lateFees) != null && (account?.myAccount?.lateFees) !== '0') && <BillingEntry title='Other Fees' amount={account?.myAccount?.lateFees} />}
            {((account?.myAccount?.latestBillAmount) != null) && <BillingEntry title='Previous Bill Amount' amount={account?.myAccount?.latestBillAmount} />}
            {((account?.myAccount?.adjustments) != null) && <BillingEntry title='Previous Bill Adjustments' amount={account?.myAccount?.adjustments} />}
            {((account?.myAccount?.latestPayment) != null) && <BillingEntry title='Payments Received' amount={account?.myAccount?.latestPayment} />}
            <Box display="flex" justifyContent="space-between" bgcolor='#EEF3F6' px={3} py={2}>
              <Typography fontSize={16} fontWeight={700} color="#010B0E">Total Charges</Typography>
              <Typography fontSize={16} fontWeight={700} color="#010B0E">{((account?.myAccount?.accountBalance) != null) && formatValue(account?.myAccount?.accountBalance)}</Typography>
            </Box>
          </Box>
        </>
        : <Box pt={5}> <ProgressLoaders height='100%' /> </Box>}
    </Box >
  )
}

interface BillingEntryProps {
  title: string
  amount: string
}

const BillingEntry: React.FC<BillingEntryProps> = (props: BillingEntryProps) => {
  const isMobile = useMobileBreakpoints()
  return <Box display="flex" justifyContent="space-between" borderBottom='solid 1px #D7E3E9' px={3} py={2}>
    <Typography fontSize={14} color="#010B0E" width={isMobile ? '70%' : 'auto'}>{props.title}</Typography>
    <Typography fontSize={14} color="#010B0E">{formatValue(props.amount)}</Typography>
  </Box>
}
