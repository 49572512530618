import { Box, Dialog, Stack, Typography } from '@mui/material'
import { MButton, Text } from 'components'
import { ROUTES_PATH } from 'config'
import { useAuth } from 'context'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import Initial from './Initial'

interface VerifyInformationModalProps {
  showModal: boolean
  setShowModal: () => void
}
const VerifyInformationModal: React.FC<VerifyInformationModalProps> = ({
  showModal,
  setShowModal
}: VerifyInformationModalProps) => {
  const { account } = useAuth()
  const navigate = useNavigate()
  const isMobile = useMobileBreakpoints()

  const mailingAddress = `${account?.myAccount?.mailingAddress?.addressLine1}, ${account?.myAccount?.mailingAddress?.city}, ${account?.myAccount?.mailingAddress?.state} - ${account?.myAccount?.mailingAddress?.postal}`
  const contactNumber =
    account?.myAccount?.mobileNumber ?? account?.myAccount?.officeNumber

  return (
    <Dialog
      open={showModal}

    >
      <Box p={3}>
        <Typography color="#000000" fontWeight={600} fontSize={24}>Let&apos;s verify your information</Typography>
        <Stack direction="column">
          <Box
            bgcolor='#F7F9FB'
            p={2}
            mt={2}
            mb={2}
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            <Initial />
            <Box>
              <Box p={1}>
                <Text color="#4d515f" fontSize="12px">
                  MAILING ADDRESS
                </Text>
                <Text color="black" fontSize="16px">
                  {mailingAddress}
                </Text>
              </Box>
              <Box p={1}>
                <Text color="#4d515f" fontSize="12px">
                  HOME / OFFICE NUMBER
                </Text>
                <Text color="black" fontSize="16px">
                  {contactNumber}
                </Text>
              </Box>
            </Box>

          </Box>
          <Text color="black" fontSize="16px">
            Please confirm your mailing address and phone number listed above are
            correct.
          </Text>
          <Stack
            my={3}
            direction={isMobile ? 'column' : 'row'}
            gap={2}
            justifyContent="space-between"
          >
            <MButton
              fullWidth
              variant="outlined"
              rounded="true"
              texttransform="none"
              size="large"
              onClick={() => { setShowModal() }}
            >
              Yes, it&apos;s correct.
            </MButton>
            <MButton
              fullWidth
              variant="outlined"
              rounded="true"
              texttransform="none"
              size="large"
              onClick={() => {
                navigate(ROUTES_PATH.ACCOUNT)
                setShowModal()
              }}
            >
              No, I need to update it.
            </MButton>
          </Stack>
        </Stack>

      </Box>

    </Dialog>
  )
}

export default VerifyInformationModal
