import { Grid } from '@mui/material'
import { AuthLayout } from 'components'
import { AuthTitle } from 'containers/Pay'
import { AuthMain, AuthWrapper } from '../SignIn'
import { Form } from './component'

export const ForgotPasswordReset: React.FC = () => {
  return (
    <AuthLayout isAuthPage={true}>
      <AuthWrapper isAuthPage={true}>
        <Grid container justifyContent='center' mb={7}>
          <Grid item xs={12} md={6}>
            <AuthMain isRounded={true}>
              <AuthTitle title="Reset Password" />
              <Form />
            </AuthMain>
          </Grid>
        </Grid>
      </AuthWrapper>
    </AuthLayout>
  )
}
