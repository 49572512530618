import { Box, Link } from '@mui/material'
import SavingCard1Img from 'assets/images/saving_card_1.png'
import SavingCard2Img from 'assets/images/saving_card_2.png'
import { Text } from 'components'
import { ROUTES_PATH } from 'config'
import type React from 'react'
import { useNavigate } from 'react-router-dom'

export const Savings: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Box
      bgcolor='#FFFFFF'
      borderRadius='4px'
    >
      <Box p={3} display="flex" flexDirection="column" gap={2}>

        <Text color='#000000' fontSize='18px' fontWeight='600'>
          Savings
        </Text>

        <Box
          bgcolor='#F7F9FB'
          display='flex'
          alignItems='center'
          p={2}
          gap={2.5}
        >
          <img src={SavingCard1Img} width='75px' height='75px' alt='Winter Averaging Explained' />
          <div>
            <Text
              fontWeight='700'
              fontSize='16px'
              color='#000000'
            >
              Winter Averaging Explained
            </Text>
            <Link sx={{ color: '#007395', textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(ROUTES_PATH.AUTHORIZEDSAVINGS) }}>Read More</Link>
          </div>
        </Box>
        <Box
          bgcolor='#F7F9FB'
          display='flex'
          alignItems='center'
          p={2}
          gap={2.5}
        >
          <img src={SavingCard2Img} width='75px' height='75px' alt='Deduct Meter Program' />
          <div>
            <Text
              fontWeight='700'
              fontSize='16px'
              color='#000000'
            >
              Deduct Meter Program
            </Text>
            <Link sx={{ color: '#007395', textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(ROUTES_PATH.AUTHORIZEDSAVINGS) }}>Read More</Link>
          </div>
        </Box>

      </Box>
    </Box >

  )
}
