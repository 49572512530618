import { Avatar, Typography } from '@mui/material'
import { useAuth } from 'context'
import type React from 'react'
import { getInitials } from 'utils'

const Initial: React.FC = () => {
    const { account } = useAuth()
    const initials = getInitials(account?.myAccount.personName ?? '')

    return (
        <Avatar sx={{ background: 'linear-gradient(356.09deg, #002E4A -151.04%, #336D91 204.82%)', width: 80, height: 80, border: 'solid 2px #336D91' }}>
            <Typography fontSize={20} alignItems="center" justifyContent="center">{initials}</Typography>
        </Avatar>
    )
}

export default Initial
