import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import MailIcon from '@mui/icons-material/Mail'
import QuizIcon from '@mui/icons-material/Quiz'
import { Box, Grid, Link, Typography } from '@mui/material'
import LogoLargeImg from 'assets/images/logo-large.jpg'
import { Text } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import { ROUTES_PATH } from 'config'
import { useNavigate } from 'react-router-dom'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

const DATA = [
  {
    id: 1,
    title: 'Frequently Asked Questions',
    desc: 'Do you have any questions? Please visit our frequently questions to find your answer.',
    link: ROUTES_PATH.AUTHORIZEDFAQS,
    linkTitle: 'Go to FAQ',
    icon: QuizIcon
  },
  {
    id: 2,
    title: 'Contact Us',
    desc: 'Need additional assistance? Contact us, we are here to help you.',
    link: ROUTES_PATH.CONTACT,
    linkTitle: 'Go to Contact Us',
    icon: MailIcon
  }
]

const DATA_UNAUTH = [
  {
    id: 1,
    title: 'Frequently Asked Questions',
    desc: 'Do you have any questions? Please visit our frequently questions to find your answer.',
    link: ROUTES_PATH.FAQS,
    linkTitle: 'Go to FAQ',
    icon: QuizIcon
  },
  {
    id: 2,
    title: 'Contact Us',
    desc: 'Need additional assistance? Contact us, we are here to help you.',
    link: ROUTES_PATH.CONTACT_US,
    linkTitle: 'Go to Contact Us',
    icon: MailIcon
  }
]

interface IServices {
  isAuthenticated?: boolean
}

export const Services = ({ isAuthenticated = true }: IServices): JSX.Element => {
  const navigate = useNavigate()
  const isMobile = useMobileBreakpoints()
  return (
    <>
      {(isMobile && isAuthenticated) && <QuickLinks />}
      <Box p={isMobile ? 3 : 5} mt={3} bgcolor="#ffffff">
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={2}>
          <Box flex={1} mr={isMobile ? 0 : 3}>
            <Typography fontSize={isMobile ? 18 : 24} fontWeight={600} color="#010B0E">
              How do I start or stop my service
            </Typography>
            <Box mt={2} textAlign="justify">
              <Typography component="span" fontSize={isMobile ? 14 : 16} color="#5D6365" mr={1}>
                Virginia American Water provides us with all of our account information and meter readings. For this reason, all of our account holders must also have an account with them.
              </Typography>
              <Typography component="span" fontSize={isMobile ? 14 : 16} fontWeight={600} color="#010B0E" mr={1}>To start or stop service, or to make changes to your name or mailing address on accounts with active service,
                you must contact Virginia American Water at <Typography component="span" color="#007395" fontWeight={600}>(800) 452-6863</Typography>, Monday through Friday from 7:00 a.m. to 7:00 p.m.
              </Typography>
              <Typography component="span" fontSize={isMobile ? 14 : 16} color="#5D6365">
                Once Virginia American Water issues your initial/final water bill, your initial/final wastewater bill with AlexRenew will follow automatically.
              </Typography>

            </Box>
          </Box>
          <Box flex={1}>
            <img src={LogoLargeImg} alt="logo" width="100%" />
          </Box>
        </Box>
        <Text
          color="#010B0E"
          fontSize="18px"
          fontWeight="600"
          margin="40px 0 24px"
        >
          Didn’t find what you were looking for? Visit our FAQ page or contact us
          directly.
        </Text>
        {isAuthenticated && (
          <Grid container spacing={2.5}>
            {DATA.map((item) => (
              <Grid key={item.id} item sm={12} md={6}>
                <Box p={2} bgcolor="#F7F9FB" borderRadius={2}>
                  <item.icon sx={{ color: '#0092BD' }} />
                  <Text color="#010B0E" fontSize="18px" margin="12px 0 4px">
                    {item.title}
                  </Text>
                  <Text color="#5D6365" fontSize="14px" margin="0 0 12px">
                    {item.desc}
                  </Text>
                  <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => { navigate(item.link) }}>
                    <Box display="flex" alignItems="center">
                      <Text color="#007395" fontSize="12px" margin="0 4px 0 0">
                        {item.linkTitle}
                      </Text>
                      <ArrowRightAltIcon sx={{ color: '#0092BD' }} />
                    </Box>
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
        {!isAuthenticated && (
          <Grid container spacing={2.5}>
            {DATA_UNAUTH.map((item) => (
              <Grid key={item.id} item sm={12} md={6}>
                <Box
                  p={2}
                  sx={{
                    background: '#F7F9FB',
                    borderRadius: '4px',
                    minHeight: '150px'
                  }}
                >
                  <item.icon sx={{ color: '#0092BD' }} />
                  <Text color="#010B0E" fontSize="18px" margin="12px 0 4px">
                    {item.title}
                  </Text>
                  <Text color="#5D6365" fontSize="14px" margin="0 0 12px">
                    {item.desc}
                  </Text>
                  <Link href={item.link}>
                    <Box display="flex" alignItems="center">
                      <Text color="#007395" fontSize="12px" margin="0 4px 0 0">
                        {item.linkTitle}
                      </Text>
                      <ArrowRightAltIcon sx={{ color: '#0092BD' }} />
                    </Box>
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </>
  )
}
