import { Box } from '@mui/material'
import { ProgressLoaders } from 'components'
import { MESSAGES, ROUTES_PATH } from 'config'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const SignInAsCustomer: React.FC = () => {
  const { handleLoginFromAdmin } = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { enqueueSnackbar } = useSnackbar()
  const email = searchParams.get('email') ?? ''
  const token = searchParams.get('token') ?? ''

  useEffect(() => {
    const loginAsync = async (): Promise<void> => {
      const success = await handleLoginFromAdmin({
        email,
        AccessToken: token,
        admin: true
      })

      if (success) {
        setIsLoading(false)
        navigate(ROUTES_PATH.DASHBOARD)
      } else {
        setIsLoading(false)
        enqueueSnackbar(`${MESSAGES.SOMETHING_WRONG_ERROR}`, {
          variant: 'error'
        })
      }
    }

    void loginAsync()
  }, [email, navigate, token, isLoading])

  return (
    <>
      {isLoading && (
        <Box width="100%" height="100%" alignItems="center">
          <ProgressLoaders />
        </Box>
      )}
    </>
  )
}
