import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/en'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Box,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  styled
} from '@mui/material'

import { MButton, ProgressLoaders, Text } from 'components'
import { MESSAGES, ROUTES_PATH, TOPIC_ITEMS, USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

export const Form: React.FC = () => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { account, currentAccount, getAccount, isAdmin, userEmail } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [showCallbackField, setShowCallbackField] = useState(false)
  const [callbackDate, setCallbackDate] = useState<Date | null>(null)
  const isMobile = useMobileBreakpoints()
  const [form, setForm] = useState<any>({
    name: '',
    billingID: '',
    accountNumber: '',
    serviceAddress: '',
    email: '',
    number: '',
    topic: '',
    subject: '',
    callBack: '',
    callBackDate: '',
    file: '',
    comments: ''
  })
  useEffect(() => {
    if (account != null) {
      setForm({
        ...form,
        name: account.myAccount.personName,
        accountNumber: account.myAccount.accountId,
        serviceAddress: account.myAccount.serviceAddresses[0].serviceAddress,
        email: account.myAccount.email,
        number: account.myAccount.alternateNumber
      })
    }
  }, [account, form])
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  const handleChange = (e: any, name: string): void => {
    if (name === 'callBack') {
      const showCallbackField = e.target.value === 'Yes'
      setShowCallbackField(showCallbackField)
      if (!showCallbackField) {
        setCallbackDate(null)
      }
    }

    setForm({ ...form, [name]: e.target.value })
  }

  const validateForm = (): boolean => {
    try {
      let error
      // ensure required fields have values
      if (form.name === '') {
        error = MESSAGES.REQUIRED_NAME_ERROR
      } else if (form.email === '') {
        error = MESSAGES.REQUIRED_EMAIL_ERROR
      } else if (form.number === '') {
        error = MESSAGES.REQUIRED_TELEPHONE_ERROR
      } else if (form.topic === '' || form.topic === 'default') {
        error = MESSAGES.SELECT_ONE_ERROR
      } else if (form.subject === '') {
        error = MESSAGES.REQUIRED_SUBJECT_ERROR
      }

      // throw snackbar error if something is missing
      if (error) {
        enqueueSnackbar(error, {
          variant: 'error'
        })
        return false
      }

      return true
    } catch (e: any) {
      return false
    }
  }

  const handleSubmit = async (): Promise<void> => {
    if (validateForm()) {
      try {
        const body = {
          ...form,
          AccessToken: authToken,
          callBackDate:
            dayjs(callbackDate).format('MM/DD/YYYY h:mm A') ?? ''
        }
        await APIs.contactUs(body)
        enqueueSnackbar(MESSAGES.FORM_SUBMIT_SUCCESS, {
          variant: 'success'
        })
        setTimeout(() => {
          navigate(ROUTES_PATH.DASHBOARD)
        }, 7000)
      } catch (e) {
        enqueueSnackbar(MESSAGES.FORM_SUBMIT_ERROR, {
          variant: 'error'
        })
      }
    }
  }

  return (
    <>
      {account !== null
        ? <Box display="flex" flexDirection="column" flex={1} gap={3} mr={2}>
          <Box>
            <Text fontSize="22px" fontWeight="700" color="#010B0E">
              Contact Us Form
            </Text>
            <span style={{ color: '#5D6365', fontSize: '12px', fontWeight: '400' }}>
              {' '}
              Please allow 1-2 business days for response.
            </span>
          </Box>
          <Box>
            <FormLabel>Name (Last, First)*</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Customer Name"
              value={form.name}
              onChange={(e) => {
                handleChange(e, 'name')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Account Number </FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Account Number"
              value={form.accountNumber}
              onChange={(e) => {
                handleChange(e, 'accountNumber')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Service Address</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Service Address"
              value={form.serviceAddress}
              onChange={(e) => {
                handleChange(e, 'ServiceAddress')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Email Address *</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Email Address"
              value={form.email}
              onChange={(e) => {
                handleChange(e, 'email')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Contact Number *</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Contact Number"
              value={form.number}
              onChange={(e) => {
                handleChange(e, 'number')
              }}
            />
          </Box>
          <Box>
            <FormLabel>Topic *</FormLabel>
            <Select
              fullWidth
              onChange={(e) => {
                handleChange(e, 'topic')
              }}
              defaultValue="default"
            >
              {TOPIC_ITEMS.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <FormLabel>Subject *</FormLabel>
            <OutlinedInput
              fullWidth
              type="text"
              placeholder="Subject"
              value={form.subject}
              onChange={(e) => {
                handleChange(e, 'subject')
              }}
            />
          </Box>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={1} alignItems={isMobile ? 'left' : 'flex-end'}>
            <FormLabel>Do you want to be called back?</FormLabel>
            <RadioGroup row value={form.callBack}
              onChange={(e) => {
                handleChange(e, 'callBack')
              }}
            >
              <FormControlLabel
                value="Yes"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                sx={{ borderLeft: '3px solid #D7E3E9', marginLeft: '0px' }}
                value="No"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
          </Box>
          {showCallbackField && (
            <Box>
              <FormLabel>Callback Date and Time</FormLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <DateTimePicker
                  value={callbackDate}
                  onChange={(date) => {
                    setCallbackDate(date)
                  }}
                />
              </LocalizationProvider>
            </Box>
          )}
          <Box>
            <FormLabel>ADDITIONAL COMMENTS (OPTIONAL)</FormLabel>
            <Textarea
              placeholder="Additional Comments"
              value={form.comments}
              onChange={(e) => {
                handleChange(e, 'comments')
              }}
            />
            <span style={{ color: '#5D6365', fontSize: '12px', fontWeight: '400' }}>
              (*) Indicates a required field
            </span>
          </Box>
          <Box>
            <MButton
              variant="contained"
              rounded="true"
              texttransform="none"
              onClick={handleSubmit}
              size="large"
            >
              Submit Form
            </MButton>
          </Box>
        </Box>
        : <ProgressLoaders />}
    </>
  )
}

export const Textarea = styled(TextareaAutosize)({
  width: '96%',
  height: '188px !important',
  resize: 'none',
  borderRadius: '2px',
  border: '2px solid #D7E3E9 !important',
  padding: '10px',
  font: 'inherit'
})

export const FormLabel = styled(InputLabel)`
  font-weight: 400;
  font-size: 12px;
  color: #4d515f;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
`
