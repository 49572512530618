import { yupResolver } from '@hookform/resolvers/yup'
import {
  Box,
  Dialog,
  IconButton,
  Link,
  OutlinedInput,
  Typography
} from '@mui/material'
import AccountNumberHelp1 from 'assets/images/AccountNumberHelp1.jpg'
import AccountNumberHelp2 from 'assets/images/AccountNumberHelp2.png'
import { CloudPay, ErrorText, MButton, SvgImage, Text } from 'components'
import { ROUTES_PATH } from 'config'
import { FormLabel } from 'containers/Auth/SignIn/component'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import * as yup from 'yup'

const schema = yup
  .object({
    account: yup.string().max(10).required('Account number is required')
  })
  .required()

interface IPay {
  account: string
}
export const Form: React.FC = () => {
  const [account, setAccount] = useState<string>('')
  const [cloudPayVisible, setCloudPayVisible] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isMobile = useMobileBreakpoints()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid }
  } = useForm<IPay>({
    mode: 'all',
    defaultValues: {
      account: ''
    },
    resolver: yupResolver(schema)
  })

  const onSubmit = (data: IPay): void => {
    setAccount(data.account)
    setCloudPayVisible(true)
  }

  const handleCloudPayClose = (): void => {
    setCloudPayVisible(false)
  }


  return (
    <>
      <Box>
        <form method="post" onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <Box display="flex" gap="5px">
              <FormLabel>Account Number</FormLabel>
              <Link
                component="button"
                onClick={() => {
                  setIsModalOpen(true)
                }}
              >
                <SvgImage name="QuestionMark" width={18} height={18} />
              </Link>
            </Box>
            <Controller
              name="account"
              control={control}
              render={({ field: { onChange, value, name } }): JSX.Element => (
                <OutlinedInput
                  name={name}
                  placeholder="Enter your account number"
                  onChange={onChange}
                  value={value}
                  type="text"
                  fullWidth
                />
              )}
            />
            {errors.account && <ErrorText>{errors.account.message}</ErrorText>}
          </Box>

          <Box mt={3} mb={2} width="100%">
            <MButton
              variant="contained"
              type="submit"
              size="large"
              rounded="true"
              disabled={!isValid}
              texttransform="none"
              fullWidth
              onSubmit={handleSubmit(onSubmit)}
            >
              Pay Bill
            </MButton>

            <Box mt={3}>
              <Link href={ROUTES_PATH.SIGNIN} underline="none">
                <Text color="#007395" fontSize="16px" align="center">
                  Back to Login
                </Text>
              </Link>
            </Box>
          </Box>
        </form>
        {cloudPayVisible && (
          <CloudPay account={account} onClose={handleCloudPayClose} />
        )}
      </Box>
      <Dialog
        open={isModalOpen}
        aria-labelledby="modal-title" maxWidth="lg" fullScreen={!!isMobile}>
        <Box p={5}>
          <Box display="flex" justifyContent='flex-end'>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => { setIsModalOpen(false) }}
              aria-label="close"
              sx={{
                '&:hover': {
                  backgroundColor: '#fff'
                }
              }}
            >
              <SvgImage name="CloseModal" width={30} height={30} />
            </IconButton>
          </Box>
          <Typography mb={5} fontSize={24} fontWeight={600} color='#010B0E' textAlign='center'>
            Where can I find my account number?
          </Typography>
          <Box display='flex' gap={5} flexDirection={isMobile ? 'column' : 'row'}>
            <Box
              sx={{
                textAlign: 'center',
                backgroundColor: '#F7F9FB',
                padding: '30px 20px',
                border: '1px solid #D7E3E9',
                borderRadius: '3px'
              }}
            >
              <SvgImage name="AccountNumberHelpIcon1" width={50} height={50} />
              <Typography sx={{ margin: '10px 0 42px 0', color: '#5D6365' }}>
                Get one of your previous bills
              </Typography>
              <img
                src={AccountNumberHelp1}
                alt="Alex Renew Footer"
                width="100%"
              />
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                backgroundColor: '#F7F9FB',
                padding: '30px 20px',
                border: '1px solid #D7E3E9',
                borderRadius: '3px'
              }}
            >
              <SvgImage name="AccountNumberHelpIcon2" width={50} height={50} />
              <Typography
                style={{ margin: '10px 0px 0px 0px', color: '#5D6365' }}
                paragraph
              >
                {'Check the right hand corner, under'}
              </Typography>
              <Typography style={{ marginBottom: '20px', color: '#5D6365' }}>
                “Account Summary”
              </Typography>
              <img
                src={AccountNumberHelp2}
                alt="Alex Renew Footer"
                width="100%"
              />
            </Box>
          </Box>
        </Box>
      </Dialog >
    </>
  )
}
