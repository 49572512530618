import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Box, MenuItem, Tabs as MuiTabs, Select, Tab, Typography, styled, type SelectChangeEvent, type SxProps } from '@mui/material'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import type React from 'react'
import { useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import {
  BillingHistory,
  BillingQuestions,
  PaymentHistory,
  PaymentLocation,
  UtilityBill
} from './components'

interface BillingTabs {
  title: string
  content: JSX.Element
}
const BILLING_TABS: BillingTabs[] = [
  { title: 'Utility Bill', content: <UtilityBill /> },
  { title: 'Payment History', content: <PaymentHistory /> },
  { title: 'Billing History', content: <BillingHistory /> },
  { title: 'Billing Questions', content: <BillingQuestions /> },
  { title: 'Payment Locations', content: <PaymentLocation /> }
]

const tabSx: SxProps = {
  p: 3,
  textTransform: 'none',
  fontSize: 18,
  color: '#007395'
}


export const Billing: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number>(0)
  const isMobile = useMobileBreakpoints()
  const handleTabChange = (_e: any, newActiveTab: number): void => {
    setActiveTab(newActiveTab)
  }
  const [isOpen, setIsOpen] = useState(false)

  const handleSelectChange = (event: SelectChangeEvent): void => {
    setActiveTab(Number(event.target.value))
  }


  return (
    <>
      {isMobile && <QuickLinks />}
      <Box mt={3}>
        {isMobile
          ? <Select variant="standard" fullWidth value={String(activeTab)} onOpen={() => { setIsOpen(true) }} onClose={() => { setIsOpen(false) }} onChange={handleSelectChange} sx={{ bgcolor: '#ffffff', borderBottom: 'solid 1px #E7EEF2', p: 2 }} disableUnderline={true} IconComponent={() => isOpen ? <ExpandLessIcon sx={{ color: '#66BED7' }} /> : <ExpandMoreIcon sx={{ color: '#66BED7' }} />}>
            {
              BILLING_TABS.map((tab, index) => {
                return <MenuItem key={index} value={index}><Typography fontSize={24} fontWeight={600} color="#007395" alignItems="center">{tab.title}</Typography></MenuItem>
              })
            }
          </Select>
          : <Tabs variant='fullWidth' value={activeTab} onChange={handleTabChange} TabIndicatorProps={{ sx: { background: '#66BED7', height: 5 } }}>
            {
              BILLING_TABS.map((tab, index) => {
                return <Tab key={index} label={tab.title} sx={{ ...tabSx, borderLeft: index === 0 ? 0 : 'solid 1px #E7EEF2' }} />
              })
            }
          </Tabs>}
        {BILLING_TABS[activeTab].content}
      </Box >
    </>

  )
}

const Tabs = styled(MuiTabs)({
  '& .MuiTab-root': {
    background: '#ffffff',
    borderBottom: 'solid 1px #E7EEF2'
  },
  '& .MuiTab-root.Mui-selected': {
    backgroundColor: '#004976',
    color: '#ffffff'
  }
})
