import { createContext, useContext, useState } from 'react'

interface ISidebarContextProps {
  openSidebar: boolean
  setOpenSidebar: (handler: boolean) => void
}

export const SidebarContext = createContext<ISidebarContextProps>({
  openSidebar: false,
  setOpenSidebar: (_handler: boolean) => { }
})

export function SidebarProvider({ children }: any): JSX.Element {
  const [openSidebar, setOpenSidebar] = useState<boolean>(false)

  return (
    <SidebarContext.Provider
      value={{
        openSidebar,
        setOpenSidebar
      }}
    >
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebar = (): ISidebarContextProps =>
  useContext(SidebarContext)
