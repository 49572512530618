import { Box } from '@mui/material'
import MonthlyAverageIcon from 'assets/icons/MonthlyAverage.svg'
import TotalUsageIcon from 'assets/icons/TotalUsage.svg'
import { Text } from 'components'
import { type IDashboardStats } from 'containers/Dashboard/Dashboard'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IProps {
  dashboardStats: IDashboardStats
}

export const PowerCompare = ({ dashboardStats }: IProps): JSX.Element => {
  const [compare, setCompare] = useState<any[]>()
  const startDate = dayjs().subtract(1, 'year').format('MMMM YYYY')
  const endDate = dayjs().format('MMMM YYYY')
  const isMobile = useMobileBreakpoints()
  useEffect(() => {
    if (dashboardStats) {
      setCompare([
        {
          id: 1,
          title: 'Monthly Average',
          image: MonthlyAverageIcon,
          value: `${dashboardStats.monthlyAverage} TGAL`
        },
        {
          id: 2,
          title: 'Total Usage',
          image: TotalUsageIcon,
          value: `${dashboardStats.totalUsage} TGAL`
        }
      ])
    }
  }, [dashboardStats])

  return (
    <Box height="100%" bgcolor="#FFFFFF" borderRadius="4px">
      <Box p={3}>
        <Box mb={3}>
          <Text color="#000000" fontSize="18px" fontWeight="600">
            Previous 12 months
          </Text>
          <Text color="#343C3E" fontSize="12px">
            {`${startDate} - ${endDate}`}
          </Text>
        </Box>
        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
          {compare?.map((item: any) => (
            <Box
              key={item.id}
              flex={1}
              display="flex"
              flexDirection={isMobile ? 'row' : 'column'}
              p={2}
              gap={1}
              border="1px solid #D7E3E9"
              borderRadius="4px"
              alignItems="center"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                borderRadius="50%"
                bgcolor="#F7F9FB"
              >
                <img src={item.image} alt={item.title} />
              </Box>
              <Box>
                <Text
                  align={isMobile ? 'left' : 'center'}
                  color="#000000"
                  fontSize="18px"
                  fontWeight="600"
                >
                  {item.value}
                </Text>
                <Text align={isMobile ? 'left' : 'center'} color="#5D6365" fontSize="14px">
                  {item.title}
                </Text>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
