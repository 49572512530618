import CampaignIcon from '@mui/icons-material/Campaign'
import EmailIcon from '@mui/icons-material/Email'
import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Switch,
  Typography,
  styled
} from '@mui/material'
import { Auth } from 'aws-amplify'
import { MButton, ProgressLoaders, SvgImage, Text } from 'components'
import QuickLinks from 'components/layouts/AuthorizedLayout/component/QuickLinks'
import {
  EMAI,
  MESSAGES,
  NOTIFICATIONS,
  PAPERLESSFLG,
  PRIMARYEMAIL,
  ROUTES_PATH,
  USER_TOKEN
} from 'config'
import { useAuth } from 'context'
import { useSnackbar } from 'notistack'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { APIs } from 'services'
import { useLocalStorage } from 'utils'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'
import { EditEmailForm, FormLabel } from './components'

type settingType =
  | typeof NOTIFICATIONS
  | typeof PAPERLESSFLG
  | typeof PRIMARYEMAIL

const INIT_NOTIFICATION = {
  COMMUNICATIONSxSMS: false,
  COMMUNICATIONSxIVR: false,
  COMMUNICATIONSxEMAI: false,
  BILLINGxSMS: false,
  BILLINGxIVR: false,
  BILLINGxEMAI: false
}

interface ShowPasswordType {
  current: boolean
  new: boolean
  confirmNew: boolean
}

interface SettingItemType {
  title: string
  icon: string
}

export const AccountPreferences: React.FC = () => {
  const { account, premiseId, currentAccount, getAccount, handleLogOut, isAdmin, userEmail } =
    useAuth()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [showPassword, setShowPassword] = useState<ShowPasswordType>({
    current: false,
    new: false,
    confirmNew: false
  })
  const [paperlessBilling, setPaperlessBilling] = useState<boolean>(false)
  const [notificationChecks, setNotificationChecks] =
    useState<any>(INIT_NOTIFICATION)
  const [changePassword, setChangePassword] = useState<any>({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  })
  const [isEmailEditing, setIsEmailEditing] = useState<boolean>(false)
  const [isEmailUpdateSuccess, setIsEmailUpdateSuccess] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMobile = useMobileBreakpoints()
  const [isPrefUpdated, setIsPrefUpdated] = useState<boolean>(false)
  const [prevAccountId, setPrevAccountId] = useState<string>('')

  useEffect(() => {
    if (prevAccountId === currentAccount?.accountId || currentAccount?.accountId === account?.myAccount?.accountId) {
      return
    }
    if (currentAccount?.accountId !== null) {
      void getAccount({
        AccessToken: authToken,
        accountId: currentAccount?.accountId,
        admin: isAdmin,
        email: userEmail
      })
    }
    setPrevAccountId((currentAccount?.accountId ?? account?.myAccount?.accountId) ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, currentAccount])

  useEffect(() => {
    if ((account?.myAccount?.notificationPreferences) != null) {
      setNotificationChecks(account.myAccount.notificationPreferences)
    } else {
      setNotificationChecks(INIT_NOTIFICATION)
    }

    if ((account?.myAccount) != null) {
      setPaperlessBilling(account.myAccount.paperlessFlg === 'Y')
    }
  }, [account])

  const handlePasswordChange = (event: any): void => {
    setChangePassword({
      ...changePassword,
      [event.target.name]: event.target.value
    })
  }

  const handleUpdatePassword = async (e: any): Promise<void> => {
    e.preventDefault()

    if (isAdmin) {
      enqueueSnackbar(MESSAGES.ADMIN_ACCESS_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }

    if (changePassword.confirmNewPassword !== changePassword.newPassword) {
      enqueueSnackbar(MESSAGES.PASSWORD_MATCH_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    }

    if (
      changePassword.newPassword.length > 0 &&
      changePassword.confirmNewPassword === changePassword.newPassword
    ) {
      try {
        const user = await Auth.currentAuthenticatedUser()
        await Auth.changePassword(
          user,
          changePassword.currentPassword,
          changePassword.newPassword
        )
        enqueueSnackbar(MESSAGES.PASSWORD_UPDATED_SUCCESS, {
          variant: 'success',
          autoHideDuration: 3000
        })
        handleLogout()
      } catch (e: any) {
        enqueueSnackbar(e.message, {
          variant: 'error',
          autoHideDuration: 3000
        })
      }
    }
  }

  const handleNotificationsChange = (event: any): void => {
    setNotificationChecks({
      ...notificationChecks,
      [event.target.name]: event.target.checked
    })
    handleUpdateSettings(event, NOTIFICATIONS)
  }

  useEffect(() => {
    return () => {
      if (isPrefUpdated) {
        void getAccount({
          AccessToken: authToken,
          accountId: currentAccount?.accountId ?? '',
          admin: isAdmin,
          email: userEmail
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrefUpdated])

  const handleUpdateSettings = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setting: settingType,
    email?: string
  ): Promise<void> => {
    setIsLoading(true)
    const [obj, value] = event.target.name.split('x')
    const body: any = {
      AccessToken: authToken,
      accountId: account?.myAccount.accountId,
      admin: isAdmin,
      email: userEmail
    }
    if (setting === NOTIFICATIONS) {
      body.updates = [
        {
          object: obj,
          value,
          value2: event.target.checked ? 'Y' : 'N'
        }
      ]
    } else if (setting === PAPERLESSFLG) {
      body.updates = [
        {
          object: PAPERLESSFLG,
          value: event.target.checked ? 'Y' : 'N'
        }
      ]
    } else if (setting === PRIMARYEMAIL) {
      body.updates = [
        {
          object: PRIMARYEMAIL,
          value: email
        }
      ]
    }

    try {
      const response = await APIs.updateUser(body)
      setIsPrefUpdated(true)
      if (response?.status === 1 && setting === PAPERLESSFLG) {
        enqueueSnackbar(MESSAGES.PREFERENCES_UPDATED_SUCCESS, {
          variant: 'success',
          autoHideDuration: 3000
        })
      } else if (response?.status === 1 && setting === PRIMARYEMAIL) {
        enqueueSnackbar(MESSAGES.PREFERENCES_UPDATED_SUCCESS, {
          variant: 'success',
          autoHideDuration: 3000
        })
        void getAccount({
          AccessToken: authToken,
          premiseId,
          accountId: account?.myAccount?.accountId
        })
        setIsEmailUpdateSuccess(true)
      } else {
        enqueueSnackbar(MESSAGES.PREFERENCES_UPDATED_SUCCESS, {
          variant: 'success',
          autoHideDuration: 3000
        })
        if (setting === NOTIFICATIONS && value !== EMAI) {
          enqueueSnackbar(MESSAGES.PREFERENCES_WARN, {
            variant: 'warning',
            autoHideDuration: 6000
          })
        }
      }
      setIsLoading(false)
    } catch (e: any) {
      if (e.response?.status === 400) {
        enqueueSnackbar(
          `${e.response.data.message}. ${MESSAGES.CONTACT_CUSTOMER_ERROR}`,
          {
            variant: 'error',
            autoHideDuration: 5000
          }
        )
      }
      // roll back changes on screen
      if (setting === NOTIFICATIONS) {
        setNotificationChecks({
          ...notificationChecks,
          [event.target.name]: !event.target.checked
        })
      } else if (setting === PAPERLESSFLG) {
        setPaperlessBilling(!event.target.checked)
      }
    }
  }

  const handlePaperlessBillingChange = (event: any): void => {
    setPaperlessBilling(event.target.checked)
    handleUpdateSettings(event, PAPERLESSFLG)
  }

  const handleEditEmail = (
    event: React.ChangeEvent<HTMLInputElement>,
    email: string
  ): void => {
    void handleUpdateSettings(event, PRIMARYEMAIL, email)
    void enqueueSnackbar(MESSAGES.EMAIL_UPDATED_SUCCESS, {
      variant: 'success',
      autoHideDuration: 8000
    })
    setTimeout(() => { void handleLogout() }, 10000)
  }

  const handleLogout = async (): Promise<void> => {
    await handleLogOut()
    navigate(ROUTES_PATH.SIGNIN)
  }

  const handleEditEmailButtonClick = (): void => {
    if (isAdmin) {
      enqueueSnackbar(MESSAGES.ADMIN_ACCESS_ERROR, {
        variant: 'error',
        autoHideDuration: 3000
      })
    } else {
      setIsEmailEditing(!isEmailEditing)
    }
  }

  const SettingItem: React.FC<SettingItemType> = (props: SettingItemType) => (
    <Box display="flex" justifyContent="center" alignItems='center'>
      <SvgImage name={props.icon} width={20} height={20} />
      <Text fontSize="16px" color="#000" nowrap="true" margin="0 0 0 12px">
        {props.title}
      </Text>
    </Box>
  )

  return (
    <>
      {isMobile && <QuickLinks />}
      {account !== null
        ? <Box mt={3} p={3} bgcolor="#ffffff" borderRadius="4px">
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={3}>
            <Box flex={1} justifyContent="space-between" alignItems="center">
              <Box p={3} border='1px solid #D7E3E9' borderRadius="4px" mb={3}>
                <Box display="flex" flexDirection="column" borderBottom="1px solid #D7E3E9" gap={2}>
                  <Box display="flex" alignItems="center" flexDirection={isMobile ? 'row' : 'column'} gap={2}>
                    <Avatar sx={{ bgcolor: '#F3FBFD', width: isMobile ? 50 : 80, height: isMobile ? 50 : 80 }}>
                      <InsertPageBreakOutlinedIcon fontSize={isMobile ? 'small' : 'large'} sx={{ color: '#004976' }} />
                    </Avatar>
                    <Typography color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight={600} textAlign="center">
                      Paperless Billing
                    </Typography>
                  </Box>
                  <Typography color="#5D6365" fontSize={isMobile ? 14 : 16} textAlign={isMobile ? 'justify' : 'center'} mb={3}>
                    When you select paperless billing, you will no longer receive a
                    paper bill in the mail, and instead your bill will be emailed to
                    you.
                  </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
                  <Typography color="#010B0E" fontSize={isMobile ? 14 : 16} textAlign={isMobile ? 'justify' : 'center'} gap={2}>
                    Do you want to enroll in paperless billing?
                  </Typography>
                  <FormControlLabel
                    value="start"
                    checked={paperlessBilling}
                    control={<Switch color="primary" />}
                    onChange={handlePaperlessBillingChange}
                    label={paperlessBilling ? <Typography fontSize={isMobile ? 14 : 16}>Yes</Typography> : <Typography fontSize={isMobile ? 14 : 16}>No</Typography>}
                    labelPlacement="start"
                    sx={{ fontSize: isMobile ? 14 : 16 }}
                  />
                </Box>
              </Box>
              <Box p={3} border='1px solid #D7E3E9' borderRadius="4px">
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Box>
                    <Typography color="#010B0E" fontWeight={600} fontSize={isMobile ? 18 : 24} mb={1}>
                      Email Address
                    </Typography>
                    <Typography color="#5D6365" fontSize={isMobile ? 14 : 16}>
                      Here you can update your email address.
                    </Typography>
                  </Box>
                  {!isMobile && <Box alignItems="center">
                    <MButton fullWidth size="large" rounded="true" variant="outlined" texttransform="none" onClick={handleEditEmailButtonClick}>
                      Edit Email
                    </MButton>
                  </Box>}
                </Box>
                <OutlinedInput fullWidth size="small" type="text" name="email" value={account?.myAccount?.email} disabled />
                {isMobile &&
                  <Box mt={3}>
                    <MButton fullWidth size="large" rounded="true" variant="outlined" texttransform="none" onClick={handleEditEmailButtonClick}>
                      Edit Email
                    </MButton>
                  </Box>}
              </Box>
              {isEmailEditing && (
                <EditEmailForm
                  handleEditEmail={handleEditEmail}
                  setIsEmailEditing={setIsEmailEditing}
                  open={isEmailEditing}
                  isEmailUpdateSuccess={isEmailUpdateSuccess}
                  isLoading={isLoading}
                  setIsEmailUpdateSuccess={setIsEmailUpdateSuccess}
                />
              )}
            </Box>
            <Box flex={1} border='1px solid #D7E3E9' borderRadius="4px" p={3}>
              <Box display="flex" alignItems="center" flexDirection={isMobile ? 'row' : 'column'} gap={2}>
                <Avatar sx={{ bgcolor: '#F3FBFD', width: isMobile ? 50 : 80, height: isMobile ? 50 : 80 }}>
                  <LockOutlinedIcon fontSize={isMobile ? 'small' : 'large'} sx={{ color: '#004976' }} />
                </Avatar>
                <Typography color="#010B0E" fontWeight={600} fontSize={isMobile ? 18 : 24}>
                  Change Password
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" mt={4} gap={3}>
                <Box>
                  <FormLabel>CURRENT PASSWORD</FormLabel>
                  <OutlinedInput
                    fullWidth
                    size="small"
                    type={showPassword.current ? 'text' : 'password'}
                    name="currentPassword"
                    value={changePassword.currentPassword}
                    onChange={handlePasswordChange}
                    placeholder="Enter your current password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword({
                              ...showPassword,
                              current: !showPassword.current
                            })
                          }}
                          edge="end"
                        >
                          {showPassword.current
                            ? (
                              <VisibilityOff sx={{ color: '#0092BD' }} />
                            )
                            : (
                              <Visibility sx={{ color: '#0092BD' }} />
                            )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box>
                  <FormLabel>NEW PASSWORD</FormLabel>
                  <OutlinedInput
                    fullWidth
                    size="small"
                    type={showPassword.new ? 'text' : 'password'}
                    name="newPassword"
                    value={changePassword.newPassword}
                    onChange={handlePasswordChange}
                    placeholder="Enter the new password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword({
                              ...showPassword,
                              new: !showPassword.new
                            })
                          }}
                          edge="end"
                        >
                          {showPassword.new
                            ? (
                              <VisibilityOff sx={{ color: '#0092BD' }} />
                            )
                            : (
                              <Visibility sx={{ color: '#0092BD' }} />
                            )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <Box>
                  <FormLabel>CONFIRM NEW PASSWORD</FormLabel>
                  <OutlinedInput
                    fullWidth
                    size="small"
                    type={showPassword.confirmNew ? 'text' : 'password'}
                    name="confirmNewPassword"
                    value={changePassword.confirmNewPassword}
                    onChange={handlePasswordChange}
                    placeholder="Enter again the new password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setShowPassword({
                              ...showPassword,
                              confirmNew: !showPassword.confirmNew
                            })
                          }}
                          edge="end"
                        >
                          {showPassword.confirmNew
                            ? (
                              <VisibilityOff sx={{ color: '#0092BD' }} />
                            )
                            : (
                              <Visibility sx={{ color: '#0092BD' }} />
                            )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </Box>
                <MButton
                  size="large"
                  variant="contained"
                  rounded="true"
                  texttransform="none"
                  onClick={handleUpdatePassword}
                >
                  Confirm New Password
                </MButton>
              </Box>
            </Box>
          </Box>
          <Box mt={5}>
            <Box mb={2}>
              <Box display="flex" alignItems="center" my={2} gap={2}>
                {isMobile
                  ? <Avatar sx={{ bgcolor: '#F3FBFD', width: isMobile ? 50 : 80, height: isMobile ? 50 : 80 }}>
                    <NotificationsIcon fontSize='small' sx={{ color: '#004976' }} />
                  </Avatar>
                  : <SvgImage name="NotificationIcon" width={26} height={26} />
                }
                <Typography color="#010B0E" fontSize={isMobile ? 18 : 24} fontWeight={600}>
                  Don&apos;t miss any information!
                </Typography>
              </Box>
              <Typography color="#5D6365" fontSize={isMobile ? 14 : 16} textAlign="justify">
                MyAlexRenew offers different ways to receive your notifications.
                Please choose your preferences below.   Note that you may still receive phone calls, for urgent issues, even
                if you do not select &quot;Call me.&quot;
              </Typography>
            </Box>
            {isMobile
              ? <Box display="flex" flexDirection="column" gap={3}>
                <Box border='1px solid #D7E3E9' borderRadius="4px">
                  <Box display="flex" bgcolor="#F7F9FB" borderRadius="4px" p={2} gap={1}>
                    <PhoneIphoneIcon fontSize='medium' sx={{ marginTop: 1, color: '#9BB8C9' }} />
                    <Box>
                      <Typography color="#010B0E" fontSize={16}>Via Text Message</Typography>
                      <Typography color="#5D6365" fontSize={14}>Send me a text message. Message and data ray might apply.</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box display="flex" p={2} justifyContent="space-between" alignItems="center">
                      <Box display="flex" gap={1}>
                        <CampaignIcon fontSize='medium' sx={{ color: '#9BB8C9' }} />
                        <Typography color="#010B0E" fontSize={16}>Communications</Typography>
                      </Box>
                      <Checkbox
                        checked={notificationChecks.COMMUNICATIONSxSMS}
                        onChange={handleNotificationsChange}
                        name="COMMUNICATIONSxSMS"
                        color="primary"
                      />
                    </Box>
                    <Box display="flex" p={2} justifyContent="space-between" alignItems="center">
                      <Box display="flex" gap={1}>
                        <ReceiptLongIcon fontSize='medium' sx={{ color: '#9BB8C9' }} />
                        <Typography color="#010B0E" fontSize={16}>Billing</Typography>
                      </Box>
                      <Checkbox
                        checked={notificationChecks.BILLINGxSMS}
                        onChange={handleNotificationsChange}
                        name="BILLINGxSMS"
                        color="primary"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box border='1px solid #D7E3E9' borderRadius="4px">
                  <Box display="flex" bgcolor="#F7F9FB" borderRadius="4px" p={2} gap={1}>
                    <EmailIcon fontSize='medium' sx={{ marginTop: 1, color: '#9BB8C9' }} />
                    <Box>
                      <Typography color="#010B0E" fontSize={16}>Via Text Email</Typography>
                      <Typography color="#5D6365" fontSize={14}>Send me an email.</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Box display="flex" p={2} justifyContent="space-between" alignItems="center">
                      <Box display="flex" gap={1}>
                        <CampaignIcon fontSize='medium' sx={{ color: '#9BB8C9' }} />
                        <Typography color="#010B0E" fontSize={16}>Communications</Typography>
                      </Box>
                      <Checkbox
                        checked={notificationChecks.COMMUNICATIONSxEMAI}
                        onChange={handleNotificationsChange}
                        name="COMMUNICATIONSxEMAI"
                        color="primary"
                      />
                    </Box>
                    <Box display="flex" p={2} justifyContent="space-between" alignItems="center">
                      <Box display="flex" gap={1}>
                        <ReceiptLongIcon fontSize='medium' sx={{ color: '#9BB8C9' }} />
                        <Typography color="#010B0E" fontSize={16}>Billing</Typography>
                      </Box>
                      <Checkbox
                        checked={notificationChecks.BILLINGxEMAI}
                        onChange={handleNotificationsChange}
                        name="BILLINGxEMAI"
                        color="primary"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box border='1px solid #D7E3E9' borderRadius="4px">
                  <Box display="flex" bgcolor="#F7F9FB" borderRadius="4px" p={2} gap={1}>
                    <EmailIcon fontSize='medium' sx={{ marginTop: 1, color: '#9BB8C9' }} />
                    <Box>
                      <Typography color="#010B0E" fontSize={16}>Via Phone Call</Typography>
                      <Typography color="#5D6365" fontSize={14}>Call me</Typography>
                    </Box>
                  </Box>
                  <Box display="flex" p={2} justifyContent="space-between" alignItems="center">
                    <Box display="flex" gap={1}>
                      <CampaignIcon fontSize='medium' sx={{ color: '#9BB8C9' }} />
                      <Typography color="#010B0E" fontSize={16}>Communications</Typography>
                    </Box>
                    <Checkbox
                      checked={notificationChecks.COMMUNICATIONSxIVR}
                      onChange={handleNotificationsChange}
                      name="COMMUNICATIONSxIVR"
                      color="primary"
                    />
                  </Box>
                </Box>
              </Box>
              : <Box overflow="auto">
                <TableView>
                  <thead>
                    <tr>
                      <th />
                      <th className="border-column">
                        <SettingItem title="Via Text Message" icon="MessageIcon" />
                      </th>
                      <th className="border-column">
                        <SettingItem title="Via Email" icon="MailFilledIcon" />
                      </th>
                      <th className="border-column">
                        <SettingItem title="Via Phone Call" icon="PhoneCallIcon" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>  <SettingItem title="Communications" icon="OutagesSIcon" /></td>
                      <td className="border-column">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={notificationChecks.COMMUNICATIONSxSMS}
                              onChange={handleNotificationsChange}
                              name="COMMUNICATIONSxSMS"
                              color="primary"
                            />
                          }
                          label="Send me a text message"
                        />
                      </td>
                      <td className="border-column">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={notificationChecks.COMMUNICATIONSxEMAI}
                              onChange={handleNotificationsChange}
                              name="COMMUNICATIONSxEMAI"
                              color="primary"
                            />
                          }
                          label="Send me an email"
                        />
                      </td>
                      <td className="border-column">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={notificationChecks.COMMUNICATIONSxIVR}
                              onChange={handleNotificationsChange}
                              name="COMMUNICATIONSxIVR"
                              color="primary"
                            />
                          }
                          label="Call me"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td><SettingItem title="Billing" icon="BillingSIcon" /></td>
                      <td className="border-column">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={notificationChecks.BILLINGxSMS}
                              onChange={handleNotificationsChange}
                              name="BILLINGxSMS"
                              color="primary"
                            />
                          }
                          label="Send me a text message"
                        />
                      </td>
                      <td className="border-column">
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={notificationChecks.BILLINGxEMAI}
                              onChange={handleNotificationsChange}
                              name="BILLINGxEMAI"
                              color="primary"
                            />
                          }
                          label="Send me an email"
                        />
                      </td>
                      <td className="border-column">
                        <Text color="#343C3E" fontSize="16px" align="center">
                          N/A
                        </Text>
                      </td>
                    </tr>
                  </tbody>
                </TableView>
              </Box>}

          </Box>
        </Box >
        : <Box mt={3}><ProgressLoaders height="100%" /></Box>}
    </>

  )
}

const TableView = styled('table')`
  border-radius: 3px;
  width: 100%;
  border: 1px solid #d7e3e9;
  border-collapse: collapse;

  .border-column {
    border-left: 2px solid #d7e3e9;
  }

  thead {
    background: #f7f9fb;
    text-align: center;
  }

  tbody {
    tr {
      &:nth-of-type(even) {
        background: #f9f9f9;
      }
    }
  }

  th,
  td {
    border: 0px;
    padding: 17px 33px;
    text-align: center;
    vertical-align: middle;

    input {
      margin-right: 10px;
    }

    svg {
      margin-right: 13px;
    }
  }
`
