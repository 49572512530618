import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  type SelectChangeEvent
} from '@mui/material'
import { MButton, ProgressLoaders } from 'components'
import { USER_TOKEN } from 'config'
import { useAuth } from 'context'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'
import { defaultOptions, useLocalStorage } from 'utils'
import { exportExcel } from 'utils/helpers/exportExcel'
import { type UsageData } from 'utils/hooks/types'
import { useMobileBreakpoints } from 'utils/hooks/useMobileBreakpoints'

interface IChartData {
  date: string[]
  value: number[]
}

export const UsageView: React.FC = () => {
  const { getUsage, premiseId, isAdmin, userEmail, currentAccount } = useAuth()
  const [authToken] = useLocalStorage(USER_TOKEN, '')
  const [usageData, setUsageData] = useState<IChartData>({
    date: [],
    value: []
  })
  const currentYear = dayjs().year()
  const [selectedYear, setSelectedYear] = useState<string>(
    currentYear.toString()
  )
  const [yearlyUsage, setYearlyUsage] = useState<UsageData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isMobile = useMobileBreakpoints()

  const yearOptions = Array.from({ length: 4 }, (_, index) =>
    (currentYear - index).toString()
  )

  const handleYearChange = (event: SelectChangeEvent): void => {
    setSelectedYear(event.target.value)
  }

  useEffect(() => {
    const fetchYearlyUsage = async (): Promise<void> => {
      setIsLoading(true)

      const startDate = dayjs(`${selectedYear}-01-01`).format('YYYY-MM-DD')
      const endDate = dayjs(`${selectedYear}-12-31`).format('YYYY-MM-DD')

      try {
        const usageValue = await getUsage({
          AccessToken: authToken,
          premiseId: currentAccount?.premiseId ?? premiseId,
          frequency: 'M',
          startDate,
          endDate,
          service: 'SEWER',
          admin: isAdmin,
          email: userEmail
        })

        if (usageValue !== null) {
          setYearlyUsage(usageValue.usage)
        }
      } catch (error) {
        console.error('Error fetching yearly usage:', error)
      } finally {
        setIsLoading(false)
      }
    }

    void fetchYearlyUsage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiseId, currentAccount, authToken, selectedYear, isAdmin, userEmail])

  useEffect(() => {
    if (yearlyUsage != null) {
      if (
        yearlyUsage?.usageHistory?.[0]?.usageData != null &&
        yearlyUsage?.usageHistory?.[0]?.usageData.length > 0
      ) {
        const date: string[] = yearlyUsage.usageHistory[0].usageData.map(
          (item: any) => dayjs(item.period).format('MMM')
        )
        const value: number[] = yearlyUsage.usageHistory[0].usageData.map(
          (item: any) => parseFloat(item.value)
        )
        setUsageData({ date, value })
      } else {
        setUsageData({ date: [], value: [] })
      }
    }
  }, [yearlyUsage])

  const options: any = {
    ...defaultOptions,
    series: [
      {
        name: 'Usage TGAL',
        data: usageData.value
      }
    ],
    xaxis: {
      categories: usageData.date
    }

  }

  const handleExportExcel = (): void => {
    const excelData = [
      ['Date', 'Value'],
      ...options.series[0].data.map(
        (value: { name: string, data: number[] }, index: number) => [
          options.xaxis.categories[index],
          value
        ]
      )
    ]

    exportExcel(
      excelData,
      'Usage History',
      `${selectedYear}_usage_history_data.xlsx`
    )
  }

  return (
    <Box mt={3} p={isMobile ? 4 : 5} bgcolor="#FFFFFF">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="20px"
      >
        <Stack direction="column">
          <Typography color="#010B0E" fontWeight={600} fontSize={isMobile ? 18 : 24}>
            Usage History
          </Typography>
          <Typography color="#5D6365" fontSize={isMobile ? 10 : 16}>
            x1000 Gallons (TGAL)
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          {!isMobile && <Typography color="#5D6365" fontSize={14}>
            Select the year to preview:
          </Typography>}
          <Select
            labelId="year"
            id="year"
            value={selectedYear}
            onChange={handleYearChange}
          >
            {yearOptions.map((year, index) => (
              <MenuItem key={index} value={year}>
                <b>{year}</b>
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Box>
      <Box mb={2} height={isMobile ? '100%' : '20vw'} >
        {isLoading && <ProgressLoaders height="100%" />}
        {!isLoading && (
          <Chart
            options={options}
            series={options.series}
            type="bar"
            height="100%"
          />
        )}
      </Box>
      <MButton
        sx={{ width: isMobile ? '100%' : 'auto' }}
        size="large"
        variant="contained"
        rounded="true"
        texttransform="none"
        onClick={handleExportExcel}
      >
        Export to Excel
      </MButton>
    </Box >
  )
}
