import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccountIcon from '@mui/icons-material/AccountCircle'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import FacebookIcon from '@mui/icons-material/Facebook'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined'
import SavingsIcon from '@mui/icons-material/Savings'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import WindowIcon from '@mui/icons-material/Window'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism'

import { ROUTES_PATH } from './routes'

export const PAYMENT_PLAN_URL = 'https://alexrenew.promise-pay.com/'

export const PAY_TOOLS = [
  { id: 1, title: 'Autopay', Icon: CurrencyExchangeOutlinedIcon, page: 3 },
  {
    id: 2,
    title: 'Paperless Billing',
    Icon: InsertPageBreakOutlinedIcon,
    page: 2
  },
  { id: 3, title: 'Pay Bill', Icon: PaymentsOutlinedIcon, page: 1 },
  {
    id: 4,
    title: 'Payment Plan',
    Icon: RequestQuoteOutlinedIcon,
    link: PAYMENT_PLAN_URL
  },
  {
    id: 5,
    title: 'View Bill',
    Icon: ReceiptOutlinedIcon,
    navigate: ROUTES_PATH.BILLING
  },
  { id: 6, title: 'Wallet', Icon: PaymentOutlinedIcon, page: 5 },
  { id: 7, title: 'Scheduled Payments', Icon: LocalAtmIcon, page: 9 }
]

export const LOGIN_ITEMS = [
  {
    title: 'One-Time Payment',
    icon: PaymentsOutlinedIcon,
    link: ROUTES_PATH.PAY
  },
  {
    title: 'Payment Plan',
    icon: RequestQuoteIcon,
    link: PAYMENT_PLAN_URL
  },
  {
    title: 'Start or Stop Service',
    icon: FileCopyIcon,
    link: ROUTES_PATH.SERVICES
  },
  {
    title: 'FAQ',
    icon: AddToPhotosIcon,
    link: ROUTES_PATH.FAQS
  },
  {
    title: 'Problems Signing In?',
    icon: LockOpenIcon,
    link: ROUTES_PATH.FORGOT_PASSWORD
  },
  {
    title: 'Savings',
    icon: SavingsIcon,
    link: ROUTES_PATH.SAVINGS
  },
  {
    title: 'Contact Us',
    icon: ChatBubbleIcon,
    link: ROUTES_PATH.CONTACT_US
  }
]

export const SERVICE_TURN_ITEMS = [
  {
    title: 'Start Service',
    content:
      'To start new MVU electrical service in your name, please call MVU customer service at 1-844-341-6469 and have the following information available:',
    ul: [
      'Service address.',
      'Social security number & driver’s license number.',
      'Federal Tax ID (if business).',
      'Primary phone number.',
      'Date to start service (additional fees for same day service, weekends, or holidays).',
      'Mailing address.'
    ],

    subcontent:
      '*There is a $15.00 charge to start new service with $5.00 per person fee for identify verification.'
  },
  {
    title: 'Stop service',
    content: 'To terminate MVU residential or commercial electric service please call customer service at 1-844-341-6469. Please have the following information available:',
    ul: ['Service address.', 'Personal identification information.']
  },
  {
    title: 'Move service',
    content: 'If you are moving within the MVU service territory please call customer service at 1-844-341-6469 and we\'d be happy to help you transfer your account to your new address.'
  }
]

export const SIDEBAR_ITEMS = [
  { title: 'Dashboard', icon: WindowIcon, href: ROUTES_PATH.DASHBOARD },
  { title: 'Usage', icon: AccessTimeIcon, href: ROUTES_PATH.USAGE },
  { title: 'Billing', icon: ReceiptLongIcon, href: ROUTES_PATH.BILLING },
  {
    title: 'Start or Stop Service',
    icon: FileCopyIcon,
    href: ROUTES_PATH.AUTHORIZEDSERVICES
  },
  {
    title: 'Savings',
    icon: SavingsIcon,
    href: ROUTES_PATH.AUTHORIZEDSAVINGS
  },
  { title: 'Donations', icon: VolunteerActivismIcon, href: ROUTES_PATH.DONATIONS },
  { title: 'Contact Us', icon: ChatBubbleIcon, href: ROUTES_PATH.CONTACT },
  { title: 'My Account', icon: AccountIcon, href: ROUTES_PATH.ACCOUNT },
  {
    title: 'Account Preferences',
    icon: SettingsOutlinedIcon,
    href: ROUTES_PATH.SETTINGS
  }
]

export const AUTH_TOPBAR_ITEMS = [
  { title: 'Start or Stop Service', href: ROUTES_PATH.SERVICES },
  { title: 'Savings', href: ROUTES_PATH.SAVINGS },
  { title: 'Efficiency', href: ROUTES_PATH.EFFICIENCY },
  { title: 'Contact Us', href: ROUTES_PATH.CONTACT_US },
  { title: 'FAQ', href: ROUTES_PATH.FAQS }
]

export const TOPBAR_ITEMS = [
  { title: 'Usage', href: ROUTES_PATH.USAGE },
  { title: 'Billing', href: ROUTES_PATH.BILLING },
  { title: 'Start or Stop Service', href: ROUTES_PATH.AUTHORIZEDSERVICES },
  { title: 'Efficiency', href: ROUTES_PATH.EFFICIENCY },
  { title: 'Contact Us', href: ROUTES_PATH.CONTACT },
  { title: 'Account', href: ROUTES_PATH.ACCOUNT },
  { title: 'Account Preferences', href: ROUTES_PATH.SETTINGS },
  { title: 'FAQ', href: ROUTES_PATH.AUTHORIZEDFAQS },
  { title: 'Dashboard', href: ROUTES_PATH.PORTAL_DASHBOARD },
  { title: 'Savings', href: ROUTES_PATH.AUTHORIZEDSAVINGS }
]

export const MESSAGES = {
  VERIFICATION_CODE_CHECK:
    'A verification code has been sent to your email. Please check your inbox and enter the code below.',
  DONATION_SENT_SUCCESS:
    'Your submission has been successfully received!',
  VERIFICATION_RESENT:
    'A new verification code has been resent. Please allow a few minutes for its delivery. Kindly check your spam folder as well.',
  ACCOUNT_VERIFIED:
    'Your account has been successfully verified. Please proceed to create a password for registration.',
  REQUIRED_NAME_ERROR: 'Please provide your name in the required field.',
  REQUIRED_EMAIL_ERROR:
    'Please enter a valid email address in the required field.',
  REQUIRED_TELEPHONE_ERROR:
    'Please provide a valid telephone number in the required field.',
  SELECT_ONE_ERROR: 'Please select a topic before proceeding.',
  REQUIRED_SUBJECT_ERROR: 'Please enter a subject in the required field.',
  CONTACT_CUSTOMER_ERROR:
    'If this issue persists, please get in touch with our customer service team.',
  OUTAGE_RETRIEVE_ERROR: 'An error occurred while retrieving outage data: ',
  SOMETHING_WRONG_ERROR: 'Oops, something went wrong: ',
  PREFERENCES_UPDATED_SUCCESS:
    'Your preferences have been successfully updated.',
  PREFERENCES_WARN: 'Note: Standard message and data rates may apply.',
  PASSWORD_UPDATED_SUCCESS:
    'Your password has been changed successfully. Please log in using your new password.',
  EMAIL_UPDATED_SUCCESS:
    'Your email has been changed successfully. Please log in using your new email.',
  PASSWORD_MATCH_ERROR: 'New Password and Confirm New Password do not match.',
  ZIP_CODE_ERROR: 'Please provide a valid zip code.',
  STREET_NAME_ERROR: 'Please enter a valid street name.',
  DATE_STOP_SERVICE_ERROR:
    'Please specify the date you would like to stop your service from.',
  WRONG_ERROR: 'Oops, an unexpected error occurred.',
  ENTER_DETAIL_ERROR:
    'Please provide details of your safety concern in the comments section.',
  SERVICE_REQUEST_SUCCESS:
    'Your service request has been successfully submitted.',
  RETRIEVE_ERROR:
    'We are currently experiencing technical difficulties and unable to retrieve your information. Please try again later.',
  IC_RETRIEVE_ERROR: 'Payments are temporarily unavailable.',
  ACCOUNT_RETRIVE_ERROR:
    'An error occurred while retrieving your account information. Please try again later.',
  FORM_SUBMIT_ERROR:
    'An error occurred while submitting the form. Please try again.',
  FORM_SUBMIT_SUCCESS:
    'Your request has been submitted successfully. You will receive a response within 24 hours. You will now be redirected to the Dashboard.',
  BILL_PDF_ERROR:
    'We are currently unable to retrieve the PDF version of your bill. Please try again later. You can view a summary of your bill and bill history on the billing tab.',
  ACCOUNT_UPDATED_SUCCESS:
    'Your account details has been successfully updated.',
  ACCOUNT_UPDATED_ERROR:
    'An error occurred while updating your account details. Please try again later.',
  UPDATE_LIMIT_EXCEEDED:
    'Please note that you can only update your mailing address once within a 24-hour period. Kindly try again later.',
  USER_CONFIRM_WARNING:
    'Please confirm your email address by entering the confirmation code sent to your inbox.',
  OUTAGES_ERROR:
    'An error occurred while retrieving your outage information. Please try again later.',
  OUTAGES_API_ERROR:
    'An error occurred while fetching outage information. Please try again later.',
  PAYMENT_INFO:
    'If you have made a payment, it may take a few minutes for the balance to reflect the payment.',
  BILL_ERROR:
    'We are currently unable to retrieve the PDF version of your bill. Please try again later. You can view a summary of your bill and bill history on the billing tab.',
  ADMIN_ACCESS_ERROR: 'Administrators are not permitted to do this action.'
}

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const USAGE_MENU_ITEMS = [
  { title: 'Calendar', label: 'Monthly View', icon: 'CalendarIcon' },
  { title: 'CalendarOne', label: 'Daily View', icon: 'Calendar1Icon' },
  { title: 'Clock', label: 'Hourly View', icon: 'ClockIcon' }
]


export const BILLING_QUESTIONS = [
  {
    title: 'Are there any convenience fees to pay my bill?',
    content:
      "When you are enrolled and logged in on the AlexRenew Customer Care Portal, you can use a checking or savings account to setup automatic or one-time payments <b>with no processing/convenience fees</b>. When paying through our <a class='accordion-link' target='_blank' href='http://myalexrenew.com/pay'>One-time Guest Web Payment</a> option or by phone through our Interactive Voice Response (IVR) system at <a class='accordion-link' href='tel:7037213500'>(703) 721-3500</a>, a processing fee is applied for all credit and debit card transactions. There is no fee for payments using a checking account. Please see applicable charges below.<br><ul><li class='accordion-list'>$3.25 for Residential accounts, maximum credit/debit card transaction amount of $400.00</li><li class='accordion-list'>$9.95 for Non-Residential accounts, maximum card/debit card transaction amount of $2,000.00</li></ul>"
  },
  {
    title: 'Can I pay via PayPal?',
    content:
      `Once you are enrolled and logged into the AlexRenew Customer Care Portal, PayPal is an accepted payment method. However, please do not pay using the PayPal app or website.<br><br>
      <a class='accordion-link' target='_blank' href='https://invoicecloud.wistia.com/medias/q9qzhmg653'>Making a Payment with PayPal</a>`
  },
  {
    title: 'How to sign up for Pay by Text?',
    content:
      `Please watch this short video to learn how.<br><br>
      <a class='accordion-link' target='_blank' href='https://invoicecloud.wistia.com/medias/8gjcs1o33y'>Making a Payment Using Pay by Text</a>`
  },
  {
    title: 'How do I store a payment method for future use?',
    content:
      "Once you are enrolled and logged in to the AlexRenew Customer Care Portal, your payment methods can be stored in your virtual wallet. Just click on “Wallet” and “Add Card” to enter a new routing # and account # (checking or savings) to use for future ACH payments. Remember to include a nickname for the payment method, which is especially helpful when you have multiple “cards” in your virtual wallet. Also note that this function is only for storing routing #’s and account #’s for ACH payments, as there is no processing fee associated with those payment options. Credit/debit card information cannot be stored as a “card” in your virtual wallet, although you are welcome to pay with a credit/debit card through our <a class='accordion-link' target='_blank' href='https://alexrenew.myutilityportal.com/pay-login/'>One-time Guest Web Payment</a> option or by phone through our Interactive Voice Response (IVR) system at <a class='accordion-link' href='tel:7037213500'>(703) 721-3500</a>, available 24 hours a day, 7 days a week. Both of these options include a small processing fee."
  },
  {
    title: 'How do I view my bill online?',
    content:
      'When you have successfully logged in, click <b>‘View Bill’</b> in the Quick Links section at the top of the screen.'
  },
  {
    title: 'How do I find out how much I owe?',
    content: `There are multiple ways to achieve this:<br><br>
    1. When you have successfully logged in, you will be directed to the "Dashboard" that will display a wastewater Summary bill widget of your owe amount.<br><br>
    2. When you have successfully logged in, click "My Account" to view the current bill details.<br><br>
    3. When you have successfully logged in, click "Billing" to view the latest billing summary and amount owed details.<br><br>
    4. You may also call 703 721-3500.`
  }
]


export const SERVICE_ITEMS = [
  {
    icon: 'ServiceIcon',
    title: 'Start Service',
    description:
      'Start Service here if you are a new customer or want to add a new service'
  },
  {
    icon: 'ServiceOneIcon',
    title: 'Stop Service',
    description: 'Stop your service here.'
  },
  {
    icon: 'ServiceThreeIcon',
    title: 'Meter Read Verification',
    description: 'Verify your meter read here.'
  },
  {
    icon: 'ServiceTwoIcon',
    title: 'Safety',
    description: 'Notify us about any safety issues'
  }
]

export const CONTACT_INFORMATION = [
  {
    id: 0,
    icon: 'phone',
    title: 'Phone',
    description:
      "<a class='contact-link href='tel:7037213500'>(703) 721-3500</a> and press option 1. Monday-Friday 6:00 a.m. to 6:00 p.m. (except holidays)"
  },
  {
    id: 1,
    icon: 'chat',
    title: 'Chat',
    description:
      'Live chat with a person is available Monday-Friday 6:00 a.m. to 6:00 p.m. (except holidays)'
  },
  {
    id: 2,
    icon: 'email',
    title: 'Email',
    description:
      "For billing or customer service issues, please use <a class='contact-link href='mailto:billing@alexrenew.com'>billing@alexrenew.com</a> and allow 1-2 business days for a response."
  }
]

export const CONTACT_SOCIAL_ITEMS = [
  {
    icon: FacebookIcon,
    title: '/alexandriarenew',
    link: 'https://www.facebook.com/alexandriarenew'
  },
  {
    icon: null,
    title: '@AlexandriaRenew',
    link: 'https://twitter.com/AlexandriaRenew'
  },
  {
    icon: LinkedInIcon,
    title: 'Alexandria Renew Enterprises',
    link: 'https://www.linkedin.com/company/alexandria-renew-enterprises/'
  }
]

export const TOPIC_ITEMS = [
  { title: 'Select one option', value: 'default' },
  { title: 'Billing', value: 'Billing' },
  { title: 'Payment', value: 'Payment' },
  { title: 'Other', value: 'Other' }
]

export const FAQS_ITEMS = [
  {
    ul: [
      {
        title: 'How do I register my account on the AlexRenew Portal?',
        content:
          `To register your online account, you will need your 10 digit account number, email address associated with your account, and your service/premise address zip code:<br><br>
          1. Navigate to <a class='accordion-link' target='_blank' href='https://myalexrenew.com/signup'>myalexrenew.com/signup</a><br><br>
          2. Enter the requested information and click Next<br><br>
          3. Create a password and reconfirm the password then click Next<br><br>
          4. You will be emailed a 6 digit code. If you do not see it in your email inbox, please check your Junk/Spam folders as the email is often found there. If you still don't see a code, you may request a new code by clicking the link that says "Didn't get a code? Request a new code by clicking here"<br><br>
          5. Enter the code and click Submit. You will know the registration was successful when you see a green check mark and it says says the registration was successful<br><br>
          6. You can now login to the portal`
      },
      {
        title: 'How do I reset my portal password?',
        content:
          `To reset your portal password, navigate to <a class='accordion-link' target='_blank' href='https://myalexrenew.com/forgot-password'>myalexrenew.com/forgot-password:</a><br><br>
          1. Enter your email address and click Next<br><br>
          2. You will be emailed a 6 digit code. If you do not see it in your email inbox, please check your Junk/Spam folders as the email is often found there. If you still don't see a code, you may request a new code by clicking the link that says "Didn't get a code? Request a new code by clicking here"<br><br>
          3. Enter the code, new password, and reconfirm the password then click Submit. You will know the password reset was successful when you see a green check mark and it says says the reset was successful<br><br>
          4. You can now login to the portal with the new password<br><br>`
      },
      {
        title: 'Can I pay via PayPal?',
        content:
          `Once you are enrolled and logged into the AlexRenew Customer Care Portal, PayPal is an accepted payment method. However, please do not pay using the PayPal app or website.<br><br>
          <a class='accordion-link' target='_blank' href='https://invoicecloud.wistia.com/medias/q9qzhmg653'>Making a Payment with PayPal</a>`
      },
      {
        title: 'How to sign up for Pay by Text?',
        content:
          `Please watch this short video to learn how.<br><br>
          <a class='accordion-link' target='_blank' href='https://invoicecloud.wistia.com/medias/8gjcs1o33y'>Making a Payment Using Pay by Text</a>`
      },
      {
        title: 'Can I make a payment directly from my bank account to pay my AlexRenew bill?',
        content:
          "Yes, you can make an ACH payment using your bank's portal, also called Bill Pay. Please ensure that the <b>AlexRenew account number</b> and/or the name on the AlexRenew account is included in the details sent by your bank. Electronic Funds Transfer (EFT) payments directly from your bank are not permitted."
      },
      {
        title: 'Can I schedule a payment for a future date?',
        content: 'Registered users of the customer portal have the option to schedule a payment for a future date prior to your due date. After selecting the bill you wish to pay you will be presented with options to pay Today or Schedule a Payment.  Select the Schedule a Payment prompts to schedule your payment.  Once scheduled you can manage your scheduled payments from the Scheduled Payments button.'
      },
      {
        title: 'Are there any convenience fees to pay my bill?',
        content:
          "When you are enrolled and logged in on the AlexRenew Customer Care Portal, you can use a checking or savings account to setup automatic or one-time payments <b>with no processing/convenience fees</b>. When paying through our <a class='accordion-link' target='_blank' href='http://myalexrenew.com/pay'>One-time Guest Web Payment</a> option or by phone through our Interactive Voice Response (IVR) system at <a class='accordion-link' href='tel:7037213500'>(703) 721-3500</a>, a processing fee is applied for all credit and debit card transactions. There is no fee for payments using a checking account. Please see applicable charges below.<br><ul><li class='accordion-list'>$3.25 for Residential accounts, maximum credit/debit card transaction amount of $400.00</li><li class='accordion-list'>$9.95 for Non-Residential accounts, maximum card/debit card transaction amount of $2,000.00</li></ul>"
      },
      {
        title: 'How can I sign up for Autopay?',
        content:
          'To enroll in autopay you must be a registered user of the AlexRenew customer portal. To register visit <a class="accordion- link" target="_blank" href="https://myalexrenew.com/">MyAlexRenew</a> and follow the registration instructions. Once registered click on the AutoPay button and follow the prompts to setup your method of payment. We are pleased to provide you autopay without fees when using you checking or savings account as your method of payment. With other methods of payment fees will apply.'
      },
      {
        title: 'How do I store a payment method for future use?',
        content:
          "Once you are enrolled and logged in to the AlexRenew Customer Care Portal, your payment methods can be stored in your virtual wallet. Just click on “Wallet” and “Add Card” to enter a new routing # and account # (checking or savings) to use for future ACH payments. Remember to include a nickname for the payment method, which is especially helpful when you have multiple “cards” in your virtual wallet. Also note that this function is only for storing routing #’s and account #’s for ACH payments, as there is no processing fee associated with those payment options. Credit/debit card information cannot be stored as a “card” in your virtual wallet, although you are welcome to pay with a credit/debit card through our <a class='accordion-link' target='_blank' href='https://alexrenew.myutilityportal.com/pay-login/'>One-time Guest Web Payment</a> option or by phone through our Interactive Voice Response (IVR) system at <a class='accordion-link' href='tel:7037213500'>(703) 721-3500</a>, available 24 hours a day, 7 days a week. Both of these options include a small processing fee."
      },
      {
        title: 'How do I view my bill online?',
        content:
          'When you have successfully logged in, click <b>‘View Bill’</b> in the Quick Links section at the top of the screen.'
      },
      {
        title: 'How do I find out how much I owe?',
        content: `There are multiple ways to achieve this:<br><br>
                  1. When you have successfully logged in, you will be directed to the "Dashboard" that will display a wastewater Summary bill widget of your owe amount.<br><br>
                  2. When you have successfully logged in, click "My Account" to view the current bill details.<br><br>
                  3. When you have successfully logged in, click "Billing" to view the latest billing summary and amount owed details.<br><br>
                  4. You may also call 703 721-3500.`
      },
      {
        title: 'What is the Winter Quarter Average?',
        content:
          'The Winter Quarter Average is a billing cap based on a customer’s water use history from December through February (Winter Quarter). The cap is used to ensure customers are billed for indoor water use only — meaning water that is used at a home and makes its way to AlexRenew’s wastewater treatment plant. Qualified customers will be billed from March through November using their actual water use or Winter Quarter Average, whichever is lower.'
      },
      {
        title: 'Why do customers have to pay a separate water/wastewater bill?',
        content:
          "Most Alexandria citizens receive two separate bills: one from Virginia American Water, which provides clean water to your home, and one from AlexRenew, which treats the wastewater that has gone down your pipes before it is released back into our waterways. For more information, please visit: <a class='accordion-link' target='_blank' href='https://alexrenew.com/what-we-do'>https://alexrenew.com/what-we-do</a>."
      },
      {
        title: 'What is the Activation Fee?',
        content:
          'The Activation Fee is a non-refundable fee of $15.00 that covers the cost to initiate service with AlexRenew. This fee is charged to all new accounts.'
      },
      {
        title:
          'Where can I get more information or ask a question about my wastewater (sewer) bill?',
        content:
          "For questions about your AlexRenew wastewater bill, contact us via email at <a class='accordion-link' href='mailto:billing@alexrenew.com'>billing@alexrenew.com</a> or by phone at <a class='accordion-link' href='tel:7037213500'>(703) 721-3500</a>, Monday through Friday from 6:00 a.m. to 6:00 p.m. (except holidays)."
      },
      {
        title:
          'Where can I get more information or ask a question about my water bill?',
        content:
          "For questions about your water bill, contact Virginia American Water at <a class='accordion-link' href='tel:8004526863'>(800) 452-6863</a>, Monday through Friday from 7:00 a.m. to 7:00 p.m. or visit them online at <a class='accordion-link' target='_blank' href='https://amwater.com/vaaw/'>https://amwater.com/vaaw/</a>."
      },
      {
        title: 'How do I start or stop my service?',
        content:
          "Virginia American Water provides us with all of our account information and meter readings. For this reason, all of our account holders must also have an account with them. To start or stop service, or to make changes to your name or mailing address on accounts with active service, you must contact Virginia American Water at <a class='accordion-link' href='tel:8004526863'>(800) 452-6863</a>, Monday through Friday from 7:00 a.m. to 7:00 p.m. Once Virginia American Water issues your initial/final water bill, your initial/final wastewater bill with AlexRenew will follow automatically."
      },
      {
        title: 'Can I receive my bill electronically?',
        content:
          'Paperless billing allows you to receive receive your billing statement electronically by email. Once enrolled in the program, you will no longer received a paper bill in the mail.<br><br> To enroll in paperless billing, click on "Account Preferences" and edit your customer billing preferences under "Paperless Billing".'
      },
      {
        title: 'Are there any fees associated with a PromisePay payment plan?',
        content:
          'There are no fees to sign up for a PromisePay payment plan. Additionally, there are no late fees, prepayment penalties, or interest fees associated with a PromisePay payment plan. If a customer with a PromisePay payment plan uses a credit or debit card to complete a payment on their PromisePay plan, there is a convenience fee of $1.95 per transaction.'
      },
      {
        title: 'Can I make a walk-in payment at AlexRenew?',
        content:
          'Check or Money Order (only) payments may be made at AlexRenew’s administrative office. The address is 1800 Limerick St, Alexandria, VA 22314. Payments are accepted Monday through Friday, excluding holidays, from 9:00 a.m. to 4:00 p.m.'
      },
      {
        title:
          ' Is there a fee for making a walk-in payment at one of the locations found on the Payment Center Locator?',
        content:
          'There might be a processing fee, please check with the store you are making the payment at.'
      },
      {
        title:
          'Who is InvoiceCloud?',
        content:
          'Invoice Cloud is a web-based, electronic billing and payment company that provides fast, safe, and convenient billing services. By automating billing and payment, customers can click and pay online while helping the environment by reducing the need for paper. We help our clients like AlexRenew simplify complex payment processes and digital interactions to drive improved customer satisfaction and financial results.'
      }
    ]
  }
]

export const EFFICIENCY_DATA = {
  title: 'Efficiency',
  subtitle: '',
  type: 'items',
  itemBackground: true,
  items: [
    {
      title: 'AC or HP Tune-up Rebate',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Business Rebate Application',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Commercial Energy Audit...',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    },
    {
      title: 'Energy Efficiency Program...',
      link: 'https://www.moval.org/mvu/efficiency-progs.html'
    }
  ]
}

export const SOCIAL_LINKS = [
  {
    id: 1,
    icon: 'FacebookIcon',
    link: 'https://www.facebook.com/alexandriarenew'
  },
  {
    id: 2,
    icon: 'TwitterIcon',
    link: 'https://twitter.com/AlexandriaRenew'
  },
  {
    id: 3,
    icon: 'LinkedinIcon',
    link: 'https://www.linkedin.com/company/alexandria-renew-enterprises'
  }
]
