export const USER_TOKEN = 'USER_TOKEN'
export const IS_FIRST_LOGIN = 'IS_FIRST_LOGIN'
export const USER_AUTH = 'USER_AUTH'
export const SUCCESS = 'SUCCESS'
export const EMAIL = 'EMAIL'
export const REMEMBER_ME = 'REMEMBER_ME'

// NOTIFICATIONS
export const PRIMARYEMAIL = 'PRIMARYEMAIL'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const PAPERLESSFLG = 'PAPERLESSFLG'
export const EMAI = 'EMAI'

// COGNITO ERROR CODES
export const ALIAS_EXISTS_EXCEPTION = 'AliasExistsException'
export const NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException'

export const EMAIL_ADDRESS_UNRECOGNIZED_ERROR = 'The E-Mail Address provided does not match with our records.'

// WELCOME MESSAGE
export const WELCOME_TITLE = 'Welcome to MyAlexRenew'
export const WELCOME_DESCRIPTION = 'Log in to view your bill, make a payment, track your wastewater usage, and set your communication preferences.'
